<template>
  <el-container>
    <el-header style="height: 70px">
      <commonHead></commonHead>
    </el-header>
    <el-main class="mainArea">
      <div class="mainInner">
        <div class="cardTitle cardStyle">
          <div class="cardTitleStyle">
            <img src="../../assets/images/img_02.png" class="orderIcon" />
            <span>待检任务</span>
            <el-input class="searchInput" placeholder="搜索生产编号" v-model="qualityTask.param.searchText" @blur="onSearch" @keyup.enter.native="onSearch" />            
          </div>
          <div class="cardTitleStyle">
            {{ qualityTask.totalCount }}项
          </div>
        </div>
        <el-table :data="qualityTask.list" class="detialTable">
          <el-table-column prop="produce_no" label="生产编号" />
          <el-table-column prop="material_type_name" label="钢种" />
          <el-table-column prop="gmt_real_end" label="日期" width="140" />
          <el-table-column prop="process_name" label="工序" />
          <el-table-column prop="equipment_sn" label="设备号" />
          <el-table-column prop="before_produce_spec" label="领料规格" />
          <el-table-column prop="after_produce_spec" label="产出规格" />
          <el-table-column prop="before_produce_weight" label="领料重量" />
          <el-table-column prop="after_produce_weight" label="产出重量">
            <template slot-scope="scope">{{ scope.row.after_produce_weight.join(",") }}</template>
          </el-table-column>
          <el-table-column prop="work_user_no" label="工号" />
          <el-table-column prop="test_state_name" label="状态" />
          <el-table-column label="操作" width="110">
            <template slot-scope="scope"><el-button class="editBtn"
                @click="toDetail(scope.row.id,scope.row.test_id)">查看</el-button></template>
          </el-table-column>
        </el-table>
        <div class="block pageBlock">
          <el-pagination align="center" @size-change="onPageSizeChange" @current-change="onPageIndexChange"
            :current-page="qualityTask.param.pageIndex" :page-sizes="[5, 7]" :page-size="qualityTask.param.pageSize"
            layout="total, sizes, prev, pager,next,jumper" :total="qualityTask.totalCount"></el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import commonHead from '../../components/CommonHead.vue'
export default {
  components: {
    'commonHead': commonHead
  },
  data() {
    return {
      qualityTask: {
        totalCount: 0,
        list: [],
        param: {
          pageIndex: 1,
          pageSize: 7,
          searchText: ''
        }
      },
      
    }
  },
  created() {
    let loginToken = localStorage.getItem('logintoken');
    if (loginToken) {
      this.$scUtil.checkLogin(this);
      //
      this.getQualityTaskList();

      this.qualityTimer = setInterval(() => {
                this.getQualityTaskList();
            }, 10000);
    }
    else {
      this.$router.replace({ name: 'Login' });
    }
    
  },
  destroyed() {
        clearInterval(this.qualityTimer);
    },
  methods: {
    onPageSizeChange(val) {
      this.currentPage = 1;
      this.qualityTask.param.pageSize = val;
      this.getTaskList();
    },
    onPageIndexChange(val) {
      this.qualityTask.param.pageIndex = val;
      this.getQualityTaskList();
    },
    onSearch() {
      this.qualityTask.param.pageIndex = 1;
      this.getQualityTaskList();
    },
    getQualityTaskList() {
      this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetQualityTaskList', this.qualityTask.param, res => {
        if (res.code == 100) {
          this.qualityTask.list = res.data;
          if (res.totalcount > 0) {
            this.qualityTask.totalCount = res.totalcount;
          }

        }

      });
    },
    toDetail(taskId,testId) {
      this.$router.push({ name: "qualityDetail", query: { taskId: taskId, testId:testId} });
    }
  }
}
</script>

<style scoped>
::v-deep.searchInput .el-input__inner {
  border: 1px solid #57606f;
  margin-left: 40px;
  width: 100%;
  background-image: url(../../assets/images/search.png);
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 20px 20px;
  padding-left: 50px;
  background-color: transparent;
  color: #fff;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cardStyle {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
}

.cardStyle a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.cardTitleStyle {
  display: flex;
  align-items: center;
  color: #fff;
}

.orderIcon {
  margin-right: 5px;
}

::v-deep.el-header {
  padding: 0px;
}

::v-deep.mainArea {
  width: 100%;
  height: calc(100vh - 70px);
  padding: 0 !important;
}

.mainInner {
  background-image: url(../../assets/images/img_09.png);
  background-size: 100% 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.equipmentArea {
  width: inherit;
  box-sizing: border-box;
  padding: 35px;
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;

}

::v-deep.el-table {
  border: 1px solid #57606f;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
  font-size:22px;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
  font-size:22px;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

.statusLabel {
  padding: 0px 5px;
  background: #00ff00;
  color: #000000;
  display: inline-block;
  border-radius: 3px;
}

.cardBetween {
  justify-content: space-between;
}

.cardFlex {
  display: flex;
  align-items: center;
}

.cir {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
}

.green {
  background: #00ff00;
}

.gray {
  background: #bfbfbf;
}

.red {
  background: #ff0000;
}

.cardLine {
  margin: 0 20px;
}

.cirSize {
  width: 25px;
  height: 25px;
  margin: 0 auto;
}

::v-deep.tableInnerInput input {
  background: transparent;
  border: 1px solid #57606f;
  text-align: center;
  color: #fff;
  height: 30px;
  line-height: 30px;
}

::v-deep.editBtn {
  padding: 5px 12px;
  font-size: 16px;
  border: 1px solid #00aeff;
  background: #79f1ff;
  color: #000;
}

.bzBtn {
  padding: 2px 10px;
  font-size: 16px;
  border: 1px solid #00aeff;
  background: #79f1ff;
  color: #fff;
  display: inline-block;
  border-radius: 2px;
  margin: 0 auto;
}

.topSize {
  margin-top: 30px;
}

::v-deep.el-table .el-table__cell {
  border-right: 1px solid #57606f;
}

::v-deep.el-table .el-table__cell:nth-last-child(1) {
  border-right: none;
}

::v-deep.dialogArea .el-dialog__header {
  display: none;
}

::v-deep.dialogArea .el-dialog__body {
  padding: 0;
}

::v-deep.el-table.detialTable tbody tr:hover {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

.bzText {
  color: #fff;
}

::v-deep.block .el-pagination {
  margin: 30px 0;
}

::v-deep.block .el-pager li {
  background: none;
  border: 1px solid #45526f;
  margin: 0 5px;
  color: #fff;
}

::v-deep.block .el-pager .active {
  color: #79f1ff !important;
  
}

::v-deep.block .el-pagination .btn-next,
::v-deep.block .el-pagination .btn-prev {
  background: center center no-repeat #45526f;
  color: #fff;
}

::v-deep.block .el-input__inner {
  background-color: transparent;
  border: 1px solid #45526f;
}

::v-deep.el-table.popupTable tbody tr.bgkRow {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-pagination button, ::v-deep.el-pagination span:not([class*=suffix])
{
  height: 40px !important;
  line-height: 40px !important;
  font-size: 23px !important;
}

::v-deep.el-pager li
{
  height: 40px !important;
  line-height: 40px !important;
  font-size: 23px !important;
}

::v-deep.pageBlock .el-input__inner,::v-deep.pageBlock ul li{
  height: 40px  !important;
  line-height: 40px  !important;
  font-size: 23px  !important;
}
</style>