<template>
  <div class="dialogInner">
    <div class="title">
      <div class="titleText">
        <div class="titleLine"></div>工艺选择
      </div>
      <img src="../assets/images/close.png" class="closeIcon" @click="onDialogClose" />
    </div>
    <el-dropdown>
      <div class="processDropDown">
        <span class="el-dropdown-link processInner">
          {{ craftItem.id ? craftItem.name : '选择工艺' }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(item, index) in craftList" :key="index" @click.native="onCraftClick(index)">{{ item.name
        }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-table class="popupTable" :data="craftItem.process_list" style="width:100%">
      <el-table-column label="生产顺序" prop="process_order"></el-table-column>
      <el-table-column label="工序名称" prop="process_name"></el-table-column>
      <el-table-column label="支持设备" prop="equipment_list">
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.equipment_list" :key="index" class="r_flex">
            {{ item.equipment_name }}
          </p>
        </template>
      </el-table-column>

    </el-table>
    <div class="btnArea">
      <el-button class="commonBtn btnStyle1" @click="onDialogClose">关闭</el-button>
      <el-button class="commonBtn btnStyle2" @click="onCraftConfirm">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    orderDetailId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      craftList: [],
      craftItem: {}
    }
  },
  created() {
    let orderDetailId = this.orderDetailId;

    //获取材料支持的工艺
    this.$scUtil.axiosPost(this, 'orderapi/GetAllCraftByOrderDetailId', { orderDetailId: orderDetailId }, res => {
      if (res.code == 100) {
        this.craftList = res.data;
      }
      else {
        this.$message(res.message);
        this.onDialogClose();
      }
    });

  }
  , methods: {
    onCraftConfirm()
    {
      if(!this.craftItem.craft_id)
      {
        this.$message('请选择工艺');
        return;
      }

      this.$emit('onCraftConfirm',this.craftItem.craft_id);
      
    },
    onCraftClick(index) {
      this.craftItem = this.craftList[index];
    },
    onDialogClose() {
      this.$emit('onDialogHide');
    }
  }
}
</script>
<style scoped>
.dialogInner {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
  padding: 30px 35px 35px 35px;
}

.title {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: bolder;

}

.titleText {
  display: flex;
  align-items: center;
}

.titleLine {
  width: 5px;
  height: 20px;
  background: #00b7ff;
  margin-right: 10px;
}

.closeIcon {
  width: 12px;
  height: 12px;
}


.btnArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonBtn {
  width: 138px;
  height: 40px;
}

.btnStyle1 {
  color: #79f1ff;
  border: 1px solid #00aeff;
  border-radius: 10px;
  background: transparent;
}

.btnStyle2 {
  background: #79f1ff;
  border: 1px solid #00aeff;
  color: #fff;
  border-radius: 10px;
}

::v-deep.el-button+.el-button {
  margin-left: 40px;
}

::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;
}

::v-deep.el-table {
  border: 1px solid #57606f;
  margin-top: 30px;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

::v-deep.popupTable {
  margin-bottom: 30px;
}

::v-deep.el-dropdown {
  width: 100%;
}

::v-deep.processDropDown {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
  display: inline-block;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

::v-deep.processInner {
  width: 100%;
  display: block;
  border: 1px solid #788196;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

::v-deep.el-icon--right {
  display: none;
}


::v-deep.el-dropdown {
  margin-top: 20px;
}

::v-deep.el-dropdown-menu {
  width: 972px;
  background: #02133a;
  border: 1px solid #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
  /* position: absolute!important;
    min-width: 222px;
    left: auto!important;
    right: 0; */
}

/* ::v-deep.popper__arrow{
    left: 50%!important;
} */

::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}
</style>