<template>
	<div>
		<div class="addDialogInner">
			<div class="cardTitleStyle">
				<div class="cardLine"></div>
				<span>基础信息</span>
			</div>

			<table class="baseInfoTable">
				<tr>
					<td class="labelTd">
						<div class="tdInner labelTdInner">*客户姓名</div>
					</td>
					<td class="firstTd">
						<div class="tdInner baseFirstTdInner"><input type="text" style="width: 75%;"
								v-model="addInfo.customer_name" />
							<el-button class="editBtn" @click="chooseCustomer">选择</el-button>
						</div>
					</td>
					<td class="labelTd">
						<div class="tdInner labelTdInner">*客户手机</div>
					</td>
					<td class="secondTd">
						<div class="tdInner baseSecondTdInner"><input type="text" v-model="addInfo.customer_phone" />
						</div>
					</td>
					<td class="labelTd">
						<div class="tdInner labelTdInner">*客户地址</div>
					</td>
					<td class="thirdTd">
						<div class="tdInner baseThirdTdInner"><input type="text" v-model="addInfo.customer_address" />
						</div>
					</td>
				</tr>
				<tr>
					<td class="labelTd">
						<div class="tdInner labelTdInner">客户要求</div>
					</td>
					<td class="firstTd">
						<div class="tdInner baseFirstTdInner"><input type="text" v-model="addInfo.customer_message" />
						</div>
					</td>
					<td class="labelTd">
						<div class="tdInner labelTdInner">下单时间</div>
					</td>
					<td class="secondTd">
						<div class="tdInner baseSecondTdInner">
							<el-date-picker v-model="addInfo.gmt_order" type="datetime" placeholder="请选择下单时间"
								value-format="yyyy-MM-dd HH:mm:ss">
							</el-date-picker>
						</div>
					</td>
					<td class="labelTd">
						<div class="tdInner labelTdInner">备注</div>
					</td>
					<td class="thirdTd">
						<div class="tdInner baseThirdTdInner"><input type="text" v-model="addInfo.remark" /></div>
					</td>
				</tr>
			</table>
			<div class="cardTitleStyle between">
				<div class="cardLeftInner">
					<div class="cardLine"></div>
					<span>订单明细</span>
				</div>
				<div @click="add">新增</div>
			</div>
			<el-table :data="addInfo.array" :row-key="getRowKey" class="orderDialogDetailTable">
				<el-table-column label="物料号" width=200>
					<template slot-scope="scope">
						<div style="font-size:14px;"><input readonly type="text" style="width: 55%;"
								:value="scope.row.material_no" />
							<el-button class="editBtn" @click="chooseMaterial(scope.$index)">选择</el-button>
							<el-button class="editBtn2" @click="onDelete(scope.$index)">x</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="val1" label="钢种" width=90>
					<template slot-scope="scope"><input readonly type="text" v-model="scope.row.steel_grade" /></template>
				</el-table-column>
				<el-table-column prop="val2" label="厚度" width=90>
					<template slot-scope="scope"><input type="text" value="12"
							v-model="scope.row.thickness" /></template>
				</el-table-column>
				<el-table-column prop="val3" label="厚度公差" width=90>
					<template slot-scope="scope"><input type="text"
							v-model="scope.row.thickness_tolerance" /></template>
				</el-table-column>
				<el-table-column prop="val4" label="宽度" width=90>
					<template slot-scope="scope"><input type="text" v-model="scope.row.width" /></template>
				</el-table-column>
				<el-table-column prop="val5" label="宽度公差" width=90>
					<template slot-scope="scope"><input type="text" v-model="scope.row.width_tolerance" /></template>
				</el-table-column>
				<el-table-column prop="val6" label="订货重量(t)" width=80>
					<template slot-scope="scope"><input type="text" v-model="scope.row.order_weight" /></template>
				</el-table-column>
				<el-table-column prop="val7" label="计划交货期" width=150>
					<template slot-scope="scope">
						<el-date-picker style="width: 180px;" v-model="scope.row.gmt_delivery_plan" type="date"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</template>
				</el-table-column>
				<el-table-column prop="val8" label="工艺名称" width="120">
					<template slot-scope="scope">
						<el-select v-model="scope.row.craft_id" placeholder="选择">
							<el-option v-for="(item, index) in scope.row.craftList" :key="item.craft_id" :label="item.name"
								:value="item.craft_id" @click.native ="choose(index, scope.$index)">
							</el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column prop="val9" label="硬度" width="70">
					<template slot-scope="scope"><input type="text" v-model="scope.row.require_hardness" /></template>
				</el-table-column>
				<el-table-column prop="val10" label="库存" width="70">
					<template slot-scope="scope"><input type="text" v-model="scope.row.require_stock" /></template>
				</el-table-column>
				<el-table-column prop="val11" label="刀弯" width="70">
					<template slot-scope="scope"><input type="text" v-model="scope.row.require_knife_bend" /></template>
				</el-table-column>
				<el-table-column prop="val12" label="卷径" width="70">
					<template slot-scope="scope"><input type="text"
							v-model="scope.row.require_roll_diameter" /></template>
				</el-table-column>
				<el-table-column prop="val13" label="其他" width="80">
					<template slot-scope="scope"><input type="text" v-model="scope.row.require_other" /></template>
				</el-table-column>
				<el-table-column prop="val14" label="备注" width="200" class-name="borderNone">
					<template slot-scope="scope"><input type="text" v-model="scope.row.remark" /></template>
				</el-table-column>
			</el-table>


		</div>
		<div class="buttonArea">
			<el-button class="commonBtn btnStyle1" @click="onClose">放弃新增</el-button>
			<el-button class="commonBtn btnStyle2" @click="onConfirm">提交订单</el-button>
		</div>
	</div>

</template>
<script>
	export default {
		props: {
			//这里传进来的参数
			orderInfo: {
				type: Object,
				default () {
					return {
						index: 1,
						customer_name: '',
						customer_phone: '',
						customer_address: '',
						gmt_order: '',
						customer_message: '',
						remark: '',
						array: [{
							material_name: '',
							material_id: '',
							material_no: '',
							steel_grade: '',
							thickness: '',
							thickness_tolerance: '',
							width: '',
							width_tolerance: '',
							order_weight: '',
							gmt_delivery_plan: '',
							require_hardness: '',
							require_stock: '',
							require_knife_bend: '',
							require_roll_diameter: '',
							require_other: '',
							remark: '',
							craft_name: '',
							craft_id: '',
							craftList: []
						}]
					}
				}
			}
		},
		data() {
			return {
				index: 1,
				addInfo: {}
			}
		},
		methods: {
			getRowKey(row) {
				return row.index;
			},
			onClose() {
				this.$emit('onCloseAdd');
			},
			chooseCustomer() {
				this.$emit('onOpenCustomer');
			},
			chooseMaterial(index) {
				this.$emit('onOpenMaterial', index);
			},
			choose(index, index2) {
				this.addInfo.array[index2].craft_name = this.addInfo.array[index2].craftList[index].name;
			},
			add() {
				this.index++;
				this.addInfo.array.push({
					index: this.index,
					material_name: '',
					material_id: '',
					material_no: '',
					steel_grade: '',
					thickness: '',
					thickness_tolerance: '',
					width: '',
					width_tolerance: '',
					order_weight: '',
					gmt_delivery_plan: '',
					require_hardness: '',
					require_stock: '',
					require_knife_bend: '',
					require_roll_diameter: '',
					require_other: '',
					remark: '',
					craft_name: '',
					craft_id: '',
					craftList: []
				})
			},
			onDelete(index) {
				this.addInfo.array.splice(index, 1)
			},
			onConfirm() {
				this.$confirm('是否确认提交订单?', '提示', {
					iconClass: "el-icon-question", //自定义图标样式
					confirmButtonText: "确认", //确认按钮文字更换
					cancelButtonText: "取消", //取消按钮文字更换
					showClose: true, //是否显示右上角关闭按钮
					type: "warning", //提示类型  success/info/warning/error
				}).then(() => {
					let data = JSON.parse(JSON.stringify(this.addInfo));
					data.array = JSON.stringify(data.array);
					this.$scUtil.axiosPost(this, 'orderApi/execute?actcmd=add', data, res => {
						this.$message(res.message);
						if (res.code == 100) {
							this.$emit('onAddOrder');
						}
					});
				});
			}
		},
		created() {
			this.addInfo = this.orderInfo;
		}
	}
</script>
<style scoped>
	::v-deep.orderDialogDetailTable .el-input__icon {
		line-height: 25px;
		margin-right: 5px;
	}

	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.cardTitleStyle {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #fff;
	}

	.cardLeftInner {
		display: flex;
		align-items: center;
	}

	.cardTitleStyle.between {
		justify-content: space-between;
	}

	.cardLine {
		width: 5px;
		height: 15px;
		background: #79f1ff;
		margin-right: 15px;
	}

	.baseInfoTable {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.baseInfoTable {
		border-collapse: collapse
	}

	.baseInfoTable tr td {
		border: 1px solid #4e5a75;
	}

	.labelTd {
		color: #009bee;
		width: 75px;
		height: 40px;
	}

	.labelTd .labelTdInner {
		width: 70px;
		padding-left: 10px;
	}

	.tdInner input {
		background: transparent;
		border: 1px solid #4e5a75;
		height: 25px;
		width: 96%;
		margin: 0 5px;
		box-sizing: border-box;
		color: #fff;
		padding-left: 10px;
	}

	.firstTd,
	.secondTd {
		width: 18%;
	}

	.firstTd .baseFirstTdInner,
	.secondTd .baseSecondTdInner {
		width: 100%;
		margin: 0 auto;
	}

	.thirdTd input {
		width: 98%;
	}

	::v-deep.el-table tr.el-table__row:hover {
		background-color: transparent;
	}

	::v-deep#app .el-table thead.is-group th.el-table__cell,
	::v-deep .el-table tr,
	::v-deep .el-table,
	.el-table__expanded-cell,
	::v-deep .el-table thead.is-group th.el-table__cell {
		background-color: transparent;
	}

	::v-deep.el-table {
		border: 1px solid #57606f;
	}

	::v-deep.el-table__body-wrapper {
		border: 1px solid #57606f;
	}

	::v-deep.el-container.is-vertical {
		height: 100%;
	}

	::v-deep.el-table .cell {
		text-align: center;
	}

	::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
		background: transparent;
	}

	::v-deep.el-table td.el-table__cell,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table th.el-table__cell.is-leaf {
		border-bottom: 1px solid #57606f;
	}

	::v-deep.el-table--border .el-table__cell,
	::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table__fixed-right-patch {
		border-right: 1px solid #57606f;
	}

	::v-deep.el-table--border::after,
	::v-deep.el-table--group::after,
	::v-deep.el-table::before {
		background-color: #57606f;
	}

	::v-deep.el-table thead {
		color: #79f1ff;
	}

	::v-deep.el-table td.el-table__cell {
		color: #fff;
	}

	::v-deep.el-table th.el-table__cell {
		background-color: transparent;
	}

	.orderDialogDetailTable {
		margin-top: 20px;
	}

	::v-deep.el-button.editBtn {
		height: 25px;
		background: #79f1ff;
		padding: 2px 5px;
		color: #000;
		border: 1px solid #00aeff;
	}

	::v-deep.el-button.editBtn2 {
		height: 25px;
		background: #ff0000;
		padding: 2px 5px;
		color: #fff;
		border: 1px solid #00aeff;
		margin-left: 5px !important;
	}

	::v-deep.orderDialogDetailTable input {
		width: 80%;
		background: transparent;
		margin: 0 auto;
		border: 1px solid #4e5a75;
		height: 25px;
		color: #fff;
		text-align: center;
	}

	::v-deep.baseSecondTdInner .el-input--suffix .el-input__inner {
		background: transparent;
		border: 1px solid #4e5a75;
		height: 25px;
		width: 96%;
		margin: 0 5px;
		box-sizing: border-box;
		color: #fff;
		padding-left: 10px;
	}


	::v-deep.baseSecondTdInner .el-input__prefix {
		right: 5px;
		left: auto;
	}

	::v-deep.baseSecondTdInner .el-input__icon {
		line-height: 25px;
	}

	::v-deep.el-table .el-table__cell {
		border-right: 1px solid #57606f;
	}

	::v-deep.el-table .cell {
		padding-left: 0;
		padding-right: 0;
	}

	::v-deep.el-table .borderNone {
		border-right: 0px solid transparent;
	}

	::v-deep.el-table .el-input__inner {
		height: 29px;
		border-radius: 0px;
	}

	.commonBtn {
		width: 138px;
		height: 40px;
	}

	.btnStyle1 {
		color: #79f1ff;
		border: 1px solid #00aeff;
		border-radius: 10px;
		background: transparent;
	}

	.btnStyle2 {
		background: #79f1ff;
		border: 1px solid #00aeff;
		color: #fff;
		border-radius: 10px;
	}

	::v-deep.el-button+.el-button {
		margin-left: 40px;
	}

	.buttonArea {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}

	::v-deep.addDialogInner {
		background: #02133a;
		padding: 20px;
		box-shadow: 0px 0px 8px 0px #00b7ff inset;
	}
</style>
