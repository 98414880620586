<template>
	<el-container class="pageContainer">
		<el-header style="height: 70px">
			<commonHead></commonHead>
		</el-header>
		<el-main class="mainArea">
			<div class="mainInner">
				<div class="cardTitle cardStyle">
					<div class="cardTitleStyle">
						<img src="../../../assets/images/img_02.png" class="orderIcon" />
						<span>订单明细列表</span>
						<el-input class="searchInput" placeholder="搜索（订单号、生产编号等）" v-model="searchText" @blur="onSearch"
							@keydown.enter.native="onSearch">
						</el-input>
					</div>
				</div>
				<el-table class="detialTable" :data="orderMaterialList" style="width: 100%; text-align:center">
					<el-table-column prop="order_no" label="订单号" />
					<el-table-column prop="produce_no" label="生产编号" />
					<el-table-column prop="material_no" label="钢种" />
					<el-table-column label="规格">
						<el-table-column prop="order_thickness" label="厚度" width="50">
						</el-table-column>
						<el-table-column prop="thickness_tolerance" label="公差" width="59">
						</el-table-column>
						<el-table-column prop="order_width" label="宽度" width="55">
						</el-table-column>
						<el-table-column prop="width_tolerance" label="公差" width="52">
						</el-table-column>
					</el-table-column>
					<el-table-column label="要求">
						<el-table-column prop="require_stock" label="库存" width="60" />
						<el-table-column prop="require_knife_bend" label="刀弯" width="60" />
						<el-table-column prop="require_roll_diameter" label="卷径" width="60" />
						<el-table-column prop="jrequire_other" label="其他" width="70" />
					</el-table-column>
					<el-table-column label="原料规格">
						<el-table-column prop="thickness" label="厚度" width="60" />
						<el-table-column prop="weight" label="重量" width="60" />
						<el-table-column prop="hardness" label="硬度" width="60" />
					</el-table-column>
					<el-table-column prop="gmt_delivery_plan" label="交货日期" />
					<el-table-column prop="state_name" label="状态" />
					<el-table-column label="操作" width="120">
						<template slot-scope="scope">
							<div class="tdButton">
								<el-button v-if="scope.row.state == 0"
									@click="onOrderMaterialProduceScheduleConfirm(scope.$index)" size="mini"
									class="autobtn">自动<br />排单</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-main>
		<div class="block">
			<el-pagination align="center" @current-change="onPageIndexChange" :current-page="pageIndex"
				:page-size="pageSize" layout="total, prev, pager,next,jumper" :total="totalCount"></el-pagination>
		</div>

	</el-container>
</template>
<script>
import {
	spanRow
} from "element-ui-table-span-method";
import commonHead from '../../../components/CommonHead.vue'

export default {
	name: "OrderPage",
	components: {
		'commonHead': commonHead
	},
	data() {
		return {
			searchText: '',
			pageIndex: 1,
			pageSize: 7,
			totalCount: 0,
			orderMaterialList: []
		}
	},
	created() {
		//获取所有设备
		let loginToken = localStorage.getItem('logintoken');
		if (loginToken) {
			this.$scUtil.checkLogin(this);
			this.getDataList();
		}
		else {
			this.$router.replace({ name: 'Login' });
		}
	},
	methods: {
		//单个订单原材料排产
		onOrderMaterialProduceScheduleConfirm(index) {
			this.$confirm('是否确认排产?', '提示', {
				iconClass: "el-icon-question", //自定义图标样式
				confirmButtonText: "确认", //确认按钮文字更换
				cancelButtonText: "取消", //取消按钮文字更换
				showClose: true, //是否显示右上角关闭按钮
				type: "warning", //提示类型  success/info/warning/error
			}).then(() => {
				let item = this.orderMaterialList[index];
				let param = {
					orderId: item.order_id,
					orderDetailId: item.order_detail_id,
					orderDetailMaterialId: item.id
				};

				this.$scUtil.axiosPost(this, 'producetaskapi/execute?actcmd=detailmaterialschedule', param, res => {
					this.$message(res.message);
					if (res.code == 100) {
						let resData = res.data;
						this.orderMaterialList[index].state = resData.material_state;
					}
				});
			});
		},
		getDataList() {
			this.$scUtil.axiosPost(this, 'OrderApi/GetOrderMaterialPageList', {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize,
				searchText: this.searchText
			}, res => {
				if (res.code == 100) {
					this.orderMaterialList = res.data;
					this.totalCount = res.totalcount;
				}

			});
		},
		onSearch() {
			this.pageIndex = 1;
			this.getDataList();
		},
		onPageIndexChange(val) {
			this.pageIndex = val;
			this.getDataList();
		}
	}
}
</script>
<style scoped>
::v-deep.el-header {
	padding: 0px;
}


::v-deep.mainArea {
	width: 100%;
	height: calc(100vh - 170px);

}

.mainInner {
	background-image: url(../../../assets/images/img_09.png);
	background-size: 100% 100%;
	padding: 35px 40px;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
}

.cardTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}

.cardStyle {
	color: #fff;
	font-size: 18px;
	font-weight: bold;
	white-space: nowrap;
}

.cardStyle a {
	color: #fff;
	text-decoration: none;
	font-size: 14px;
}

.cardTitleStyle {
	display: flex;
	align-items: center;
	color: #fff;
}

.orderIcon {
	margin-right: 5px;
}

::v-deep.searchInput .el-input__inner {
	border: 1px solid #57606f;
	margin-left: 40px;
	width: 100%;
	background-image: url(../../../assets/images/search.png);
	background-repeat: no-repeat;
	background-position: 15px center;
	background-size: 20px 20px;
	padding-left: 50px;
	background-color: transparent;
	color: #fff;
}


::v-deep.searchInput input::-webkit-input-placeholder {
	color: #999;
}

::v-deep.searchInput input::-moz-input-placeholder {
	color: #999;
}

::v-deep.searchInput input::-ms-input-placeholder {
	color: #999;
}

::v-deep.el-button.addBtn {
	background: #79f1ff;
	border: 1px solid #00aeff;
	color: #000;
	padding: 5px 10px;
	font-size: 16px;
	font-weight: bolder;
}

.rect {
	width: 70%;
	height: 8px;
	background: rgba(0, 183, 255, 0.23);
	border-radius: 20px;
	margin-right: 10px;
}

.scopeFlex {
	display: flex;
	align-items: center;
}

.rectInner {
	height: 8px;
	background: #79f1ff;
	border-radius: 20px;
}

::v-deep.el-table tr.el-table__row:hover {
	background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
	background-color: transparent;
}

::v-deep.el-table {
	border: 1px solid #57606f;
}

::v-deep.el-table__body-wrapper {
	border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
	height: 100%;
}

::v-deep.el-table .cell {
	text-align: center;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
	background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
	border-bottom: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
	border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
	background-color: #57606f;
}

::v-deep.el-table thead {
	color: #79f1ff;
}

::v-deep.el-table td.el-table__cell {
	color: #fff;
}


::v-deep.el-table.detialTable tbody tr:hover {
	background: #02133a;
	box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.dialogArea .el-dialog {
	background: #02133a;
	box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.addDialog .el-dialog {
	background: transparent;
}


/* ::v-deep.dialogArea .el-dialog__header, */
::v-deep.addDialog .el-dialog__header {
	display: none;
}

::v-deep.block .el-pagination {
	margin: 30px 0;
}

::v-deep.block .el-pager li {
	background: none;
	border: 1px solid #45526f;
	margin: 0 5px;
	color: #fff;
}
::v-deep.block .el-pager .active {
  color: #79f1ff !important;
  
}

::v-deep.block .el-pagination .btn-next,
::v-deep.block .el-pagination .btn-prev {
	background: center center no-repeat #45526f;
	color: #fff;
}

::v-deep.block .el-input__inner {
	background-color: transparent;
	border: 1px solid #45526f;
}

.tdButton {
	display: flex;
	align-items: center;
}

::v-deep.editBtn {
	padding: 5px 12px;
	font-size: 16px;
	border: 1px solid #00aeff;
	background: #79f1ff;
	color: #000;color: #fff;
}

::v-deep.el-button.green {
	background: #8fc31f;
	border: none;
	color: #fff;
}

::v-deep.el-button.btnInsert {
	padding: 6px;
}

::v-deep.el-button.blue {
	background: #79f1ff;
	border: none;
	color: #fff;
}

::v-deep.el-button.yellow {
	background: #fffc00;
	border: none;
	color: #000;
}

::v-deep.el-button.redBtn {
	background: #ec4848;
	border: none;
	color: #fff;
}

::v-deep.el-button--mini,
::v-deep.el-button--mini.is-round {
	padding: 2px 3px;
}


::v-deep.pageContainer .el-dialog {
	background: none;
	min-width: 1043px;
}

::v-deep.pageContainer .el-dialog .el-dialog__header {
	display: none;
}

::v-deep.pageContainer .el-dialog .el-dialog__body {
	padding: 0px;
}

::v-deep.pageContainer .processDialogArea {
	background: none;
	min-width: 894px;
}
</style>
