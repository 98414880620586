<template>
  <el-row class="pageHead ">
    <el-col :span="6">
      <div class="grid-content bg-purple weight headLeft">{{ now }}</div>
    </el-col>
    <el-col :span="12">
      <div class="grid-content bg-purple-light bigColor">江勤美金属制品生产进程控制系统</div>
    </el-col>
    <el-col :span="6">
      <div class="grid-content bg-purple weight floatRight" @click="onLogout">
        <span>退出登录</span>
        <img src="../assets/images/exit.png" class="exitImg" />
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      timer: null,
      now: new Date()
    }
  },
  methods: {
    beforeUnloadFunc() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    onLogout() {
      const _this=this;
      this.$confirm('是否确认退出登录?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        localStorage.removeItem('logintoken');
        _this.$router.replace({ name: "Login" });
      });
    }
  },
  created() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.now = this.$scUtil.dateFormatter(new Date(), 'Y-M-D h:m:s')
      }, 1000)
    }
    window.addEventListener("beforeunload", e => { this.beforeUnloadFunc(e) });
  }
  , destroyed() {
    window.addEventListener("beforeunload", e => { this.beforeUnloadFunc(e) });
  }
}
</script>
<style scoped>
.weight {
  color: #fff;
}

.floatRight {
  cursor:pointer;
  float: right;
  margin-right: 50px;
  font-size: 22px;
}

.bigColor {
  color: #fff;
  font-size: 26px;
  text-align: center;
  font-weight: bolder;
}

.pageHead {
  width: 100%;
  background: url(../assets/images/img_01.png);
  height: 70px;
  line-height: 70px;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.headLeft {
  margin-left: 50px;
  font-size: 22px;
}

.exitImg {
  margin-left: 5px;
}
</style>