<template>
    <el-container class="loginContainer">
      <el-header class="loginHeader">
        江勤美金属制品综合管理系统
      </el-header>
      <el-main class="loginMain">
        <el-row>
          <el-col :span="24" class="loginMainFlex loginFrom">
            <div class="loginLabel">用户登录</div>
            <el-form ref="form" :model="form">
              <el-form-item style="width:100%">
                <el-input class="inputName" v-model="form.loginName" placeholder="请输入登录名称"></el-input>
              </el-form-item>
              <el-form-item style="width:100%">
                <el-input class="inputName inputPassword" show-password v-model="form.loginPwd"
                  placeholder="请输入登录密码"></el-input>
              </el-form-item>
              <el-form-item style="width:100%" class="codeAre">
                <el-input class="inputName inputCode" v-model="form.code" placeholder="请输入验证码" style="width:70%"></el-input>
                <div class="captcha_code">
                  <img :src="codeImgUrl" ref="code_img" class="codeImg" @click="onCodeImgChange" />
                </div>
              </el-form-item>
              <el-form-item style="width:100%" class="rememberArea">
                <el-checkbox-group v-model="form.rememberPassword">
                  <el-checkbox label="记住密码" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item style="width:100%">
                <el-button type="primary" class="loginBtn" @click="onLogin">登录</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </template>
  <script>
  
  export default ({
    name: "LoginPage",
    data() {
      return {
        form: {
          loginName: '',
          loginPwd: '',
          code: '',
          rememberPassword: ''
        },
        codeImgBaseUrl:'',
        codeImgUrl: '',
        codeGuid:''
      }
    },
    created() {
  
      this.codeGuid=this.$scUtil.getGuid();
  
      let loginInfoStr=localStorage.getItem('loginInfo');
      if(loginInfoStr)
      {
        let loginInfo=JSON.parse(loginInfoStr);
        this.form=loginInfo;
        this.form.code='';
      }
  
      this.codeImgBaseUrl=this.$scUtil.globalData.apiServer+'commonapi/GetCaptchaImage?guid='+this.codeGuid;
      this.codeImgUrl=this.codeImgBaseUrl;
  
    },
    methods: {
      onCodeImgChange()
      {
        this.codeImgUrl=this.codeImgBaseUrl+"&t="+new Date();
      },
      onLogin() {
        if (!this.form.loginName) {
          this.$message('登录名称不能为空');
          return;
        }
  
        if (!this.form.loginPwd) {
          this.$message('登录密码不能为空');
          return;
        }
  
        if(!this.form.code)
        {
          this.$message('验证码不能为空');
          return;
        }
  
        this.form.codeGuid=this.codeGuid;
  
        this.$scUtil.axiosPost(this, 'sysuserapi/execute?actcmd=login', this.form, res => {
          if (res.code == 100) {
            let loginToken = res.data.login_token;
            localStorage.setItem('logintoken',loginToken);
            if(this.form.rememberPassword)
            {
              localStorage.setItem('loginInfo',JSON.stringify(this.form));
            }
            else{
              localStorage.removeItem('loginInfo');
            }
            
            this.$router.push({ name: "orderList" });
          }
          else{
            this.$message(res.message);
          }
        });
      }
    }
  })
  </script>
  <style scoped>
  .loginContainer {
    background-image: url(../../assets/images/l_bg.png);
    background-size: 100% 100%;
    height: 100%;
    min-height: 100vh;
  }
  
  .loginHeader {
    height:114px;
    font-size: 38px;
    color: #fff;
    font-weight: bolder;
    text-align: center;
    line-height: 90px;
  }
  
  .loginFrom {
    background: url(../../assets/images/l_bg2.png);
    background-size: 100% 100%;
    width: 629px;
    height: 586px;
    padding: 100px 100px;
  }
  
  .loginMain {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ::v-deep.inputName .el-input__inner {
    background-image: url(../../assets/images/user.png);
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 24px 26px;
    padding-left: 50px;
    background-color: rgba(11, 161, 248, 0.4);
    border: 1px solid #1076c2;
    color: #fff;
    font-size: 18px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 0px;
  }
  
  
  ::v-deep.inputPassword .el-input__inner {
    background-image: url(../../assets/images/password.png);
  }
  
  ::v-deep.inputCode .el-input__inner {
    background-image: url(../../assets/images/code.png);
  }
  
  ::v-deep input::-webkit-input-placeholder {
    color: #fff;
  }
  
  ::v-deep input::-moz-input-placeholder {
    color: #fff;
  }
  
  ::v-deep input::-ms-input-placeholder {
    color: #fff;
  }
  
  ::v-deep .el-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .codeImg {
    width: 120px;
    height: 50px;
    margin-left: 45px;
  }
  
  ::v-deep.el-form-item {
    margin-bottom: 25px;
  }
  
  ::v-deep.el-form-item.codeAre {
    margin-bottom: 0px;
  }
  
  ::v-deep.el-form-item.rememberArea {
    margin-bottom: 10px;
  }
  
  ::v-deep .el-form-item__content {
    display: flex;
  }
  
  ::v-deep .el-checkbox {
    color: #fff;
    font-size: 21px;
  }
  
  ::v-deep label.el-checkbox__inner::after {
    color: #0d76cc;
  }
  
  ::v-deep span.el-checkbox__inner {
    background-color: rgba(11, 161, 248, 0.4);
    border: 1px solid #1078cc;
  }
  
  ::v-deep .el-button.loginBtn {
    width: 100%;
    height: 50px;
    background: #4aabfe;
    color: #fff;
    font-size: 20px;
    font-weight: bolder;
    border-radius: 0px;
  }
  
  .loginLabel {
    color: #fff;
    font-size: 30px;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }
  </style>
  