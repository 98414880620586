<template>
    <div class="taggingInner">
        <div class="taggingTitle">
            生产状况修改
            <img src="../assets/images/close.png" class="closeIcon" @click="onClose" />
        </div>
        <div class="taggingBody">
            <div class="itemCell">
                <div class="tagTitle">产出厚度：</div>
                <el-input class="storeInput" placeholder="请录入产出厚度" v-model="taskEdit.thickness"></el-input>
            </div>
            <div class="itemCell">
                <div class="tagTitle">产出宽度：</div>
                <el-input class="storeInput" placeholder="请录入产出宽度" v-model="taskEdit.width"></el-input>
            </div>
            <div class="itemCell">
                <div class="tagTitle">产出硬度：</div>
                <el-input class="storeInput" placeholder="请录入产出硬度" v-model="taskEdit.hardness"></el-input>
            </div>
            <div class="itemCell">
                <div class="tagTitle">产出重量：</div>
                <el-input class="storeInput" placeholder="请录入产出重量" v-model="taskEdit.weight"></el-input>
            </div>
            <div class="itemCell">
                <div class="tagTitle">备注：</div>
                <el-input class="storeInput" placeholder="请录入备注" v-model="taskEdit.remarks"></el-input>
            </div>
            <div class="itemCell">
            </div>
            <div class="itemCell">
            </div>
            <div class="buttonArea">
                <el-button class="btnCommon btnStyle1" @click="onClose">取消</el-button>
                <el-button class="btnCommon btnStyle2" @click="onConfirm">确认</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['selectInfo'],
    data() {
        return {
            taskEdit: this.selectInfo??{}
        }
    },
    created() {
        console.log(this.selectInfo)
        this.taskEdit=this.selectInfo;
    },
    watch: {
        selectInfo: {
            handler(newVal, oldVal) {
                console.log(newVal, oldVal)
                this.taskEdit=this.selectInfo;
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        onConfirm() {
            this.$emit('onConfirmEdit', this.taskEdit);
        },
        onClose() {
            this.$emit('onCloseEdit', true);
        }
    }
}
</script>
<style scoped>
.taggingTitle {
    widows: 100%;
    height: 50px;
    background: #79f1ff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 18px;
    font-weight: bolder;
}

.closeIcon {
    position: absolute;
    right: 4%;
    width: 12px;
    height: 12px;
}

.taggingBody {
    background: #02133a;
    box-shadow: 0px 0px 8px 0px #00b7ff inset;
    width: inherit;
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.taggingBody .itemCell {
    display: flex;
    margin-bottom: 40px;
    line-height: 40px;
    width: 100%;
    color: #fff;
    height: 40px;
}

.tagTitle {
    font-size: 14px;
    width: 100px;
    text-align: right;
    margin-right: 10px;
}

::v-deep .storeInput input {
    background: none;
    border: 1px solid #57606f;
    color: #fff;
}

::v-deep .storeInput input::placeholder {
    color: #606266;
}


::v-deep .btnCommon {
    width: 150px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
}

::v-deep .btnStyle1 {
    border: 1px solid #00aeff;
    color: #79f1ff;
    background: transparent;
}

::v-deep .btnStyle2 {
    border: 1px solid #00aeff;
    background: #79f1ff;
    color: #fff;
}

.buttonArea {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

::v-deep.el-button+.el-button.btnStyle2 {
    margin-left: 30px;
}
</style>
  