<template>
  <div  class="equipmentArea">
    <div class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition">
      <div class="hidden-columns">
        <div ></div>
        <div ></div>
        <div ></div>
        <div ></div>
      </div>
      <div class="el-table__header-wrapper">
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__header" style="width: 100%;">
          <colgroup>
            <col name="el-table_1_column_1" style="width: 25%;">
            <col name="el-table_1_column_2" style="width: 25%;">
            <col name="el-table_1_column_3" style="width: 25%;">
            <col name="el-table_1_column_4" style="width: 25%;">
           
          </colgroup>
          <thead class="has-gutter">
            <tr class="">
              <th colspan="1" rowspan="1" class="el-table_1_column_1     is-leaf el-table__cell el-table__fixed-right-patch">
                <div class="cell">工序：<span class="infoText">{{ equipmentInfo.process_name?equipmentInfo.process_name: process_name}}</span></div>
              </th>
              <th colspan="1" rowspan="1" class="el-table_1_column_2     is-leaf el-table__cell el-table__fixed-right-patch">
                <div class="cell">设备名称：<span class="infoText">{{ equipmentInfo.equipment_name }}</span></div>
              </th>
              <th colspan="1" rowspan="1" class="el-table_1_column_3     is-leaf el-table__cell el-table__fixed-right-patch">
                <div class="cell">设备状态：<span :class="equipmentInfo.work_state==10?'status-normal':'status-maintain'">{{ equipmentInfo.work_state_name }}</span></div>
              </th>
              <th colspan="1" rowspan="1" class="el-table_1_column_4     is-leaf el-table__cell el-table__fixed-right-patch">
                <div class="cell">工号：<span class="infoText">{{ equipmentInfo.user_no }}</span></div>
              </th>
              
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {

    equipmentSn: {
      type: String,
      required: false
    },
    processName:{
      type: String,
      required: false
    }

  },
  data() {
    return {
      equipmentInfo: {
        process_name:""
      },
      userInfo: {},
      equipmentTable: [],
      process_name:""
    }
  },
  created() {
    this.equipmentInfo.equipment_sn = this.equipmentSn;
    this.userInfo.user_no = this.userNo;
    this.process_name=this.processName;
    this.getEquipmentInfo();

  },
  methods: {
    getEquipmentInfo() {
      this.$scUtil.axiosPost(this, 'EquipmentApi/GetOneBySn', { equipmentSn: this.equipmentInfo.equipment_sn }, res => {
        if (res.code == 100) {
          this.equipmentInfo = res.data;
          var data = {
            equipment_sn: this.equipmentInfo.equipment_sn,
            equipment_name: this.equipmentInfo.equipment_name,
            process_name: this.equipmentInfo.process_name,
            work_state: this.equipmentInfo.work_state,
            work_state_name: this.equipmentInfo.work_state_name,
            user_no: this.equipmentInfo.user_no,
          };
          this.equipmentTable.push(data);
        }

      });
    },

  }
}
</script>
<style scoped>
::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;
}

::v-deep.el-table {
  border: 1px solid #57606f;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
  line-height: 28px !important;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
  font-size: 24px;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

.status-normal {
  padding: 5px;
  background: #00ff00;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
}

.status-maintain {
  padding: 5px;
  background: #ff0000;
  color: #fff;
  display: inline-block;
  border-radius: 3px;
}

.infoText{
  color: #fff;
}
</style>
