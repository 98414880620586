<template>
    <div class="taggingInner">
        <div class="taggingTitle">
            生产状况标注
            <img src="../assets/images/close.png" class="closeIcon" @click="onClose" />
        </div>
        <div class="taggingBody">
            <div class="flexBtn">
                <el-button v-if="taskStateShow==0||taskStateShow==1||taskStateShow==4" :class="isConfirmBegin==1?'btnStyle1  commonBtn':'btnStyle2  commonBtn'" @click="onConfirmBegin">开始</el-button>
                <el-button v-if="taskStateShow==2" :class="isConfirmGua==1?'btnStyle1  commonBtn':'btnStyle2  commonBtn'" @click="onConfirmGua">挂起</el-button>
                <el-button v-if="taskStateShow==2" :class="isConfirmEnd==1?'btnStyle1  commonBtn':'btnStyle2  commonBtn'" @click="onConfirmEnd">完成</el-button>
                <!-- <el-button class="commonBtn btnStyle2" @click="onStock">转库存</el-button> -->
            </div>
            <el-button class="confirmBtn" @click="onConfirm">确认</el-button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['stateShow'],
    data() {
        return {
            isConfirmEnd: 0,
            isConfirmBegin: 0,
            taskState:0,
            taskStateShow:this.stateShow,
            isConfirmGua:0
        }
    },
    methods: {
        onStock() {
            this.$emit('onShowStock', true);
        },
        onConfirm() {
            if(this.isConfirmEnd==0&&this.isConfirmBegin==0&&this.isConfirmGua==0){
                this.$message("请标注任务状态");
                return;
            }
            this.$emit('onConfirmMark', {taskState:this.taskState});
        },
        onClose() {
            this.$emit('onCloseMark', true);
        },
        onConfirmBegin(){
            if(this.isConfirmBegin==1){
                this.isConfirmBegin=0;
            }
            else{
                this.isConfirmBegin=1;
                this.isConfirmEnd=0;
                this.isConfirmGua=0;
            }
            this.taskState=2;
        },
        onConfirmEnd(){
            if(this.isConfirmEnd==1){
                this.isConfirmEnd=0;
            }
            else{
                this.isConfirmEnd=1;
                this.isConfirmBegin=0;
                this.isConfirmGua=0;
            }
            this.taskState=3;
        },
        onConfirmGua(){
            if(this.isConfirmGua==1){
                this.isConfirmGua=0;
            }
            else{
                this.isConfirmGua=1;
                this.isConfirmBegin=0;
                this.isConfirmEnd=0;
            }
            this.taskState=4;
        }
    }
}
</script>
<style scoped>
.taggingTitle {
    widows: 100%;
    height: 50px;
    background: #79f1ff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 18px;
    font-weight: bolder;
}

.closeIcon {
    position: absolute;
    right: 4%;
    width: 12px;
    height: 12px;
}

.taggingBody {
    background: #02133a;
    box-shadow: 0px 0px 8px 0px #00b7ff inset;
    width: inherit;
    padding: 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

::v-deep.btnOk {
    width: 100%;
    height: 54px;
    background: #00ff00;
    font-size: 20px;
    font-weight: bolder;
    color: #000;
    border: none;
    border-radius: 0;
}

::v-deep.confirmBtn {
    width: 70%;
    height: 40px;
    background: #79f1ff;
    font-size: 20px;
    font-weight: bolder;
    color: #fff;
    border: none;
    border-radius: 0;
    margin: 40px 0 0 -10px;
}


::v-deep.el-button+.el-button.confirmBtn {
    margin: 40px 0 0 0px;
}

.flexBtn {
    display: flex;
    align-items: center;
}

.commonBtn {
    width: 100px;
    height: 40px;

    border-radius: 0;
    font-size: 14px;
}

.btnStyle1 {
    background: #00ff00 !important;
    border: 1px solid #00ff00 !important;
    color: #000000 !important;

}

.btnStyle2 {
    border: 1px solid #fff !important;
    background: none !important;
    color: #fff !important;

}

::v-deep.el-button+.el-button.btnStyle1 {
    margin-left: 30px;
}
::v-deep.el-button+.el-button.btnStyle2 {
    margin-left: 30px;
}
</style>