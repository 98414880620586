import Vue from "vue"
import VueRouter from 'vue-router'

import Login from "../layout/Login.vue"
import ProductionManage from '../layout/production/manage.vue'
import ProductionLogin from '../layout/production/productionLogin.vue'
import OrderList from "../layout/order/order_list.vue"
import OrderLogin from "../layout/order/orderLogin.vue"
import taskList from "../layout/task/list.vue"
import orderDetailList from '../layout/order/detail/list.vue'
import orderMaterialList from '../layout/order/detail/material_list.vue'

import QualityList from '../layout/quality/quality_list.vue'
import QualityDetail from '../layout/quality/quality_detail.vue'

import TaskLengZha from '../layout/task/LengZha.vue'
import TaskReChuLi from '../layout/task/ReChuLi.vue'
import TaskDaoJuan from '../layout/task/DaoJuan.vue'
import TaskFenTiao from '../layout/task/FenTiao.vue'
import TaskLaBian from '../layout/task/LaBian.vue'
import TaskJianYan from '../layout/task/JianYan.vue'

import QualityTestLogin from '../quality/QualityTestLogin.vue'
import QualityTestList from '../quality/QualityTestList.vue'
import QualityTestDetail from '../quality/QualityTestDetail.vue'


Vue.use(VueRouter)
export default new VueRouter({
  routes: [
    {
      path: '/',
      name: "Login",
      component: Login,
      meta: {
        title: '登录'
      }
    },
    {
      path: '/productionLogin',
      name: 'productionLogin',
      component: ProductionLogin,
      meta: {
        title: '生产看板登录'
      }
    },
    {
      path: '/production/manage',
      name: 'productionManage',
      component: ProductionManage,
      meta: {
        title: '生产看板'
      }
    },
    {
      path: '/orderLogin',
      name: 'orderLogin',
      component: OrderLogin,
      meta: {
        title: '订单登录'
      }
    },
    {
      path: '/order/list',
      name: 'orderList',
      component: OrderList,
      meta: {
        title: '订单列表'
      }
    },
    {
      path: '/order/detail/list',
      name: 'orderDetailList',
      component: orderDetailList,
      meta: {
        title: '订单明细'
      }
    },
    {
      path: '/order/material/list',
      name: 'orderMaterialList',
      component: orderMaterialList,
      meta: {
        title: '原料生产列表'
      }
    },
    {
      path: '/quality/list',
      name: 'qualityList',
      component: QualityList,
      meta: {
        title: '质检列表'
      }
    },
    {
      path: '/quality/detail',
      name: 'qualityDetail',
      component: QualityDetail,
      meta: {
        title: '质检明细'
      }
    },
    {
      path: '/task/LengZha',
      name: 'taskLengZha',
      component: TaskLengZha,
      meta: {
        title: '冷轧'
      }
    },
    {
      path: '/task/ReChuLi',
      name: 'taskReChuLi',
      component: TaskReChuLi,
      meta: {
        title: '热处理'
      }
    },
    {
      path: '/task/DaoJuan',
      name: 'taskDaoJuan',
      component: TaskDaoJuan,
      meta: {
        title: '倒卷'
      }
    },
    {
      path: '/task/FenTiao',
      name: 'taskFenTiao',
      component: TaskFenTiao,
      meta: {
        title: '分条'
      }
    },
    {
      path: '/task/list',
      name: 'taskList',
      component: taskList,
      meta: {
        title: '工单列表'
      }
    },
    {
      path: '/task/LaBian',
      name: 'taskLaBian',
      component: TaskLaBian,
      meta: {
        title: '拉边'
      }
    },
    {
      path: '/task/JianYan',
      name: 'taskJianYan',
      component: TaskJianYan,
      meta: {
        title: '检验'
      }
    },{
      path: '/QualityTestLogin',
      name: "qualityTestLogin",
      component: QualityTestLogin,
      meta: {
        title: '质检登录'
      }
    }
    ,{
      path: '/QualityTestList',
      name: "qualityTestList",
      component: QualityTestList,
      meta: {
        title: '质检列表'
      }
    },{
      path: '/QualityTestDetail',
      name: "qualityTestDetail",
      component: QualityTestDetail,
      meta: {
        title: '质检明细'
      }
      
    }
  ]
})