<template >
    <div class="pageInner">
        <div class="title">质检系统</div>
        <div class="detailList">
            <div class="titleBetween">
                <div class="listTitle">
                    <div class="line"></div>
                    <div class="text">待检任务</div>
                    <div class="search"><el-input class="searchInput" placeholder="搜索生产编号"
                            v-model="qualityTask.param.searchText" @blur="onSearch" @keyup.enter.native="onSearch" /></div>
                </div>
                <div>{{ qualityTask.totalCount }}项</div>
            </div>
            <div class="card" v-for="(item, index) in qualityTask.list" :key="index">
                <div class="cardTitle">
                    <div class="titleli">生产编号：{{ item.produce_no }}</div>
                    <div
                        :class="item.test_state == 1 ? 'titleli green' : item.test_state == -1 ? 'titleli red' : 'titleli blue'">
                        {{ item.test_state_name }}</div>
                </div>
                <div class="cardDetail">
                    <div class="cardli">
                        <div class="gray">钢种：</div>{{ item.material_type_name }}
                    </div>
                    <div class="cardli">
                        <div class="gray">工序：</div>{{ item.process_name }}
                    </div>
                    <div class="cardli">
                        <div class="gray">设备：</div>{{ item.equipment_name }}
                    </div>
                    <div class="cardli">
                        <div class="gray">领料规格：</div>{{ item.before_produce_spec }}
                    </div>
                    <div class="cardli">
                        <div class="gray">产出规格：</div>{{ item.after_produce_spec }}
                    </div>
                    <div class="cardli">
                        <div class="gray">领料重量：</div>{{ item.before_produce_weight }}
                    </div>
                    <div class="cardli">
                        <div class="gray">产出重量：</div>{{ item.after_produce_weight.join(",") }}
                    </div>
                    <div class="cardli">
                        <div class="gray">工号：</div>{{ item.work_user_no }}
                    </div>
                    <div class="cardli">
                        <div class="gray">日期：</div>{{ item.gmt_real_end }}
                    </div>

                    <div class="cardBtn">
                        <button class="lookBtn" @click="toDetail(item.id, item.test_id)">查看</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            qualityTask: {
                totalCount: 0,
                list: [],
                param: {
                    pageIndex: 1,
                    pageSize: 5,
                    searchText: '',
                    loading: false,
                    finished: false,
                }
            },

        }
    },
    mounted() {
        document.addEventListener('scroll', this.debounce(this.onScroll, 500))
    },
    created() {
        let loginToken = localStorage.getItem('logintoken');
        if (loginToken) {
            this.$scUtil.checkLogin(this);
            //
            this.getQualityTaskList();
        }
        else {
            this.$router.replace({ name: 'qualityTestLogin' });
        }

    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            let scrollTop = document.documentElement.scrollTop//滚动条在Y轴滚动过的高度
            let scrollHeight = document.documentElement.scrollHeight//滚动条的高度
            let clientHeight = document.documentElement.clientHeight//浏览器的可视高度
            //console.log(scrollTop, scrollHeight, clientHeight)
            if (scrollTop + clientHeight == scrollHeight) {
                //console.log('触底了');
                this.getQualityTaskList();
            }

        },
        debounce(fn, delay) {
            let timer = null; // 借助闭包
            return function () {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(fn, delay); // 简化写法
            };
        },

        onPageSizeChange(val) {
            this.currentPage = 1;
            this.qualityTask.param.pageSize = val;
            this.getTaskList();
        },
        onPageIndexChange(val) {
            this.qualityTask.param.pageIndex = val;
            this.getQualityTaskList();
        },

        onSearch() {
            this.qualityTask.param.pageIndex = 1;
            this.getQualityTaskList();
        },
        getQualityTaskList() {
            var data = this.qualityTask;
            if (data.param.loading || data.param.finished) {
                return;
            }
            this.qualityTask.param.loading = true;

            this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetQualityTaskList', data.param, res => {
                let finished = false;
                if (res.code == 100) {
                    res.data.forEach(item => {
                        item.gmt_real_end=this.$scUtil.dateFormatter(new Date(item.gmt_real_end), 'Y/M/D h/m');
                    });
                    data.list = data.list.concat(res.data);
                    if (res.data.length == 0) {
                        finished = true;
                    }
                    if (res.totalcount > 0) {
                        this.qualityTask.totalCount = res.totalcount;
                    }
                }
                this.qualityTask.param.pageIndex = data.param.pageIndex + 1;
                this.qualityTask.param.loading = false;
                this.qualityTask.param.finished = finished;
                this.qualityTask.list = data.list;

            });
        },
        toDetail(taskId, testId) {
            this.$router.push({ name: "qualityTestDetail", query: { taskId: taskId, testId: testId } });
        }
    }
}


</script>
<style scoped>
.title {
    width: 100%;
    height: 50px;
    background: #0071c9;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;
}

.pageInner {
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.detailList {
    padding: 0 22px;
}

.line {
    width: 6px;
    height: 20px;
    background: #0071c9;
    margin-right: 10px;
}

.search {
    margin-left: 20px;
}

.search input {
    background: transparent;
}

.listTitle {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 15px;
    color: #000;
}

.card {
    width: 100%;
    border-radius: 10px;
    padding: 0 20px 20px 20px;
    background: #fff;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.cardTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #dddddd;
    color: #000000;
    font-size: 13px;
}

.blue {
    color: #0071c9;
}

.green {
    color: #44bb5d;
}

.red {
    color: #d03a2b;
}

.cardli {
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    color: #000;
    line-height: 25px;
}

.gray {
    color: #686868;
}

.cardDetail {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
}

.cardBtn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.lookBtn {
    width: 90px;
    height: 35px;
    border-radius: 50px;
    background: #0071c9;
    border: none;
    color: #fff;
}

.titleBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>