import Vue from 'vue';
import router from '../src/router/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import scUtil from '../static/js/scUtil.js';
import axios from 'axios';
import VueAxios from 'vue-axios'

Vue.prototype.$scUtil=scUtil;

Vue.use(router);
Vue.use(ElementUI);
Vue.use(VueAxios,axios);

Vue.config.productionTip = false

router.beforeEach((to, form, next) => {
  if (to.meta.title) {
      document.title = to.meta.title + ' - 江勤美'
  } else {
      document.title = '江勤美'
  }
  next()
});

new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')
