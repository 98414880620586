
<template>
    <div class="dialogInner">
      <div class="title">
        <div class="titleText">
          <div class="titleLine"></div>分条明细
        </div>
        <img src="../assets/images/close.png" class="closeIcon" @click="onClose" />
      </div>
      <div class="searchArea">
        <div class="itemCell">
                <div class="tagTitle">原料宽度：</div>
                <el-input class="tableInnerInput" placeholder="原料宽度" v-model="slitInfo.before_produce_width"></el-input>
            </div>
            <div class="itemCell">
                <div class="tagTitle">分条数量：</div>
                <el-input class="tableInnerInput" placeholder="分条数量" @blur="onSlit" v-model="slitInfo.slit_number"></el-input>
            </div>
            <div class="itemCell">
                <div class="tagTitle">备注：</div>
                <el-input class="tableInnerInput" placeholder="备注" v-model="slitInfo.remarks"></el-input>
            </div>
      </div>
      <el-table class="popupTable" :row-class-name="tableRowClassName" :data="slitInfo.list" style="width:100%">
                <el-table-column label="生产编号" prop="produce_no">
                    <template slot-scope="scope">
                        <el-input class="tableInnerInput" v-model="scope.row.produce_no"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="宽度" prop="width">
                    <template slot-scope="scope">
                        <el-input class="tableInnerInput" v-model="scope.row.width"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="重量" prop="weight">
                    <template slot-scope="scope">
                        <el-input class="tableInnerInput" v-model="scope.row.weight"></el-input>
                    </template>
                </el-table-column>
            </el-table>
      
      <div class="btnArea">
        <el-button class="commonBtn btnStyle2" @click="onConfirm">确认保存</el-button>
      </div>
    </div>
  </template>

<script>
export default {
    props: ['selectInfo'],
    data() {
        return {
            slitInfo: {
                id:this.selectInfo.id ?? 0,
                process_order:this.selectInfo.process_order ?? 0,
                order_detail_material_id:this.selectInfo.order_detail_material_id??0,
                before_produce_width: this.selectInfo.before_produce_width ?? 0,
                slit_number: this.selectInfo.slit_number ?? 0,
                remarks: this.selectInfo.remarks ?? '',
                list: []

            },
        }
    },
    created() {
        this.getSlitList();
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        onConfirm() {
            var slitList = this.slitInfo.list;
            if (slitList.length<1) {
                this.$message("请维护分条信息");
                return;
            }
            this.$emit('onConfirmSlit', this.slitInfo);
        },
        onClose() {
            this.$emit('onCloseSlit', true);
        },
        getSlitList() {
            this.$scUtil.axiosPost(this, 'orderApi/GetOrderMaterialSlitList', {orderDetailMaterialId:this.selectInfo.order_detail_material_id}, res => {
                if (res.code == 100) {
                    this.slitInfo.list = res.data;
                }

            });
        },
        onSlit(e){
            var slitNumber=e.target.value;
            if(parseInt(slitNumber)<1){
                this.$message("请填写正确的分条数量");
                return;
            }
            var data={
                orderDetailMaterialId:this.selectInfo.order_detail_material_id,
                beforeProduceWidth:this.selectInfo.before_produce_width,
                slitNumber:e.target.value,
                processOrder:this.selectInfo.process_order,
            };

            this.$scUtil.axiosPost(this, 'produceTaskApi/GetDetailMaterialSlit', data, res => {
                if (res.code == 100) {
                    this.slitInfo.list = res.data;
                }

            });



        }

    }
}
</script>
<style scoped>
.dialogInner {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
  padding: 30px 35px 35px 35px;
}

.title {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: bolder;

}

.titleText {
  display: flex;
  align-items: center;
}

.titleLine {
  width: 5px;
  height: 20px;
  background: #00b7ff;
  margin-right: 10px;
}

.searchBtn {
  width: 60px;
  height: 25px;
  border: 1px solid #79f1ff;
  font-size: 16px;
  color: #79f1ff;
  background: transparent;
  border-radius: 50px;
  text-align: center;
  line-height: 25px;
  margin-left: 20px;
  cursor: pointer;
}

.closeIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.searchArea {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.searchArea .itemCell {
    display: flex;
    line-height: 40px;
    width: 50%;
    color: #fff;
    height: 40px;
  }
  
  
  .tagTitle {
    font-size: 14px;
    color: #fff;
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
.searchLeft {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

.searchLeft input {
  flex: 1;
  background: transparent;
  border: 0px solid transparent;
  height: 100%;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
}

::v-deep.searchLeft input[type=text]:focus {
  outline: 0px solid transparent;
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

::v-deep input::-webkit-input-placeholder {
  color: #999;
}

::v-deep input::-moz-input-placeholder {
  color: #999;
}

::v-deep input::-ms-input-placeholder {
  color: #999;
}

.btnArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonBtn {
  width: 138px;
  height: 40px;
  cursor: pointer;
}

.btnStyle1 {
  color: #79f1ff;
  border: 1px solid #00aeff;
  border-radius: 10px;
  background: transparent;
}

.btnStyle2 {
  background: #79f1ff;
  border: 1px solid #00aeff;
  color: #000;
  border-radius: 10px;
  margin-left: 30px;
}

::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;
}

::v-deep.el-table {
  border: 1px solid #57606f;
  margin-top: 30px;
  margin-bottom: 30px;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

::v-deep.block .el-pagination {
  margin: 30px 0;
}

::v-deep.block .el-pager li {
  background: none;
  border: 1px solid #45526f;
  margin: 0 5px;
  color: #fff;
}

::v-deep.block .el-pager .active {
  color: #79f1ff !important;
  
}

::v-deep.block .el-pagination .btn-next,
::v-deep.block .el-pagination .btn-prev {
  background: center center no-repeat #45526f;
  color: #fff;
}

::v-deep.block .el-input__inner {
  background-color: transparent;
  border: 1px solid #45526f;
}

::v-deep.el-table.popupTable tbody tr.bgkRow {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-button+.el-button {
  margin-left: 30px;
}

::v-deep.tableInnerInput input {
    background: none;
    border: 1px solid #57606f;
    color: #fff;
}
  
::v-deep .tableInnerInput input::placeholder {
    color: #606266;
  }
</style>
    