<template>
	<el-container class="pageContainer">
		<el-header style="height: 70px">
			<commonHead></commonHead>
		</el-header>
		<el-main class="mainArea">
			<div class="mainInner">
				<div class="cardTitle cardStyle">
					<div class="cardTitleStyle">
						<img src="../../assets/images/img_02.png" class="orderIcon" />
						<span>订单列表</span>
						<el-input class="searchInput" placeholder="搜索订单号/用户" v-model="keyWord" @blur="search" @keydown.enter.native="search">
						</el-input>
					</div>
					<div class="cardTitleStyle">
						<el-button class="addBtn" @click="openDialog">新增</el-button>
					</div>
				</div>
				<el-table class="detialTable" :data="orderList" style="width: 100%; text-align:center">
					<el-table-column prop="order_no" label="订单号">
					</el-table-column>
					<el-table-column prop="customer_name" label="用户">
					</el-table-column>
					<el-table-column prop="steel_grade" label="钢种">
					</el-table-column>
					<el-table-column label="规格">
						<el-table-column prop="thickness" label="厚度" width=70>
						</el-table-column>
						<el-table-column prop="thickness_tolerance" label="公差" width=70>
						</el-table-column>
						<el-table-column prop="width" label="宽度" width=70>
						</el-table-column>
						<el-table-column prop="width_tolerance" label="公差" width=70>
						</el-table-column>
					</el-table-column>
					<el-table-column prop="order_weight" label="订货量(T)" width=70>
					</el-table-column>
					<el-table-column prop="gmt_delivery_plan" label="计划交货期">
					</el-table-column>
					<el-table-column prop="gmt_delivery" label="实际交货期">
					</el-table-column>
					<el-table-column label="用户要求">
						<el-table-column prop="require_hardness" label="硬度" width=70>
						</el-table-column>
						<el-table-column prop="require_stock" label="库存" width=70>
						</el-table-column>
						<el-table-column prop="require_knife_bend" label="刀弯" width=70>
						</el-table-column>
						<el-table-column prop="require_roll_diameter" label="卷径" width=70>
						</el-table-column>
						<el-table-column prop="require_other" label="其他" width=70>
						</el-table-column>
					</el-table-column>
					<el-table-column prop="state_name" label="交货状态">
					</el-table-column>
					<el-table-column label="生产进度" width=170>
						<template slot-scope="scope">
							<div class="scopeFlex">
								<div class="rect">
									<div class="rectInner" :style="('width:'+ scope.row.percent +'%')"></div>
								</div>
								{{scope.row.percent + '%'}}
							</div>

						</template>
					</el-table-column>
					<el-table-column label="操作" width="110">
						<template slot-scope="scope">
							<el-button @click="onShowOrder(scope.row.order_id)" class="editBtn">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-main>
		<div class="block">
			<el-pagination align="center" @current-change="onPageIndexChange" :current-page="pageIndex"
				:page-size="pageSize" layout="total, prev, pager,next,jumper" :total="totalCount"></el-pagination>
		</div>
		<el-dialog v-if="show" :visible.sync="show" width="50%" class="dialogArea">
			<order-dialog :id="id"></order-dialog>
		</el-dialog>

		<el-dialog :visible.sync="addShow" width="70%" class="addDialog">
			<addOrderDialog :orderInfo="orderInfo" @onCloseAdd="closeDialog" @onOpenCustomer="openCustomer"
				@onOpenMaterial="openMaterial" @onAddOrder="addOrder"></addOrderDialog>
		</el-dialog>

		<el-dialog :visible.sync="materialShow" width="70%" class="addDialog">
			<addMaterial :index="index" @onCloseMaterial="closeMaterial" @onChooseMaterial="chooseMaterial">
			</addMaterial>
		</el-dialog>

		<el-dialog :visible.sync="customerShow" width="70%" class="addDialog">
			<addCustomer @onCloseCustomer="closeCustomer" @onChooseCustomer="chooseCustomer"></addCustomer>
		</el-dialog>
	</el-container>
</template>
<script>
	import {
		spanRow
	} from "element-ui-table-span-method";
	import orderDialog from '../../components/OrderDialog.vue'
	import commonHead from '../../components/CommonHead.vue'
	import addOrderDialog from '../../components/AddOrderDialog.vue'
	import addMaterial from '../../components/AddMaterial.vue'
	import addCustomer from '../../components/AddCustomer.vue'
	export default {
		name: "OrderPage",
		components: {
			'order-dialog': orderDialog,
			'commonHead': commonHead,
			'addOrderDialog': addOrderDialog,
			'addMaterial': addMaterial,
			'addCustomer': addCustomer
		},
		data() {
			return {
				materialShow: false,
				addShow: false,
				customerShow: false,
				orderInfo: {
					customer_name: '',
					customer_phone: '',
					customer_address: '',
					gmt_order: '',
					customer_message: '',
					remark: '',
					array: [{
						material_name: '',
						material_id: '',
						material_no: '',
						steel_grade: '',
						thickness: '',
						thickness_tolerance: '',
						width: '',
						width_tolerance: '',
						order_weight: '',
						gmt_delivery_plan: '',
						require_hardness: '',
						require_stock: '',
						require_knife_bend: '',
						require_roll_diameter: '',
						require_other: '',
						remark: '',
						craft_name: '',
						craft_id: '',
						craftList: []
					}]
				},
				show: false,
				id: 0,
				keyWord: '',
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				orderList: [],
				index: 0,
				tableSpanOption: [{
					index: 0,
					field: "order_no"
				}]
			}
		},
		created() {
			this.getOrderList();
		},
		methods: {
			openDialog() {
				this.addShow = true;
			},
			closeDialog() {
				this.addShow = false;
			},
			addOrder() {
				this.orderInfo = {
					customer_name: '',
					customer_phone: '',
					customer_address: '',
					gmt_order: '',
					customer_message: '',
					remark: '',
					array: [{
						material_name: '',
						material_id: '',
						material_no: '',
						steel_grade: '',
						thickness: '',
						thickness_tolerance: '',
						width: '',
						width_tolerance: '',
						order_weight: '',
						gmt_delivery_plan: '',
						require_hardness: '',
						require_stock: '',
						require_knife_bend: '',
						require_roll_diameter: '',
						require_other: '',
						remark: '',
						craft_name: '',
						craft_id: '',
						craftList: []
					}]
				}

				this.closeDialog();
				this.search();
			},
			openCustomer() {
				this.customerShow = true;
			},
			closeCustomer() {
				this.customerShow = false;
			},
			chooseCustomer(item) {
				console.log(item)
				this.orderInfo.customer_name = item.customer_name;
				this.orderInfo.customer_phone = item.customer_phone;
				this.orderInfo.customer_address = item.customer_address;
				this.closeCustomer();
			},
			openMaterial(index) {
				console.log(index)
				this.index = index;
				this.materialShow = true;
			},
			closeMaterial() {
				this.index = 0;
				this.materialShow = false;
			},
			chooseMaterial(item) {
				console.log(this.index)
				this.orderInfo.array[this.index].material_id = item.id;
				this.orderInfo.array[this.index].material_name = item.material_name;
				this.orderInfo.array[this.index].material_no = item.material_no;
				this.orderInfo.array[this.index].steel_grade = item.material_type_name;
				this.$scUtil.axiosPost(this, 'MaterialApi/GetCraftList', {
					id: item.id
				}, res => {
					if (res.code == 100) {
						this.orderInfo.array[this.index].craftList = res.data;
						this.closeMaterial();
					}
				});
			},
			getOrderList() {
				this.$scUtil.axiosPost(this, 'orderApi/GetOrderList', {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					keyWord: this.keyWord
				}, res => {
					if (res.code == 100) {
						this.orderList = res.data;
						this.totalCount = res.totalcount;
					}

				});
			},
			search() {
				this.pageIndex = 1;
				this.getOrderList();
			},
			onPageIndexChange(val) {
				this.pageIndex = val;
				this.getOrderList();
			},
			onConfirm(val) {
				console.log(val);
			},
			onShowOrder(id) {
				this.id = id;
				this.show = true;
			},
			onCloseOrder() {
				this.id = 0;
				this.show = false;
			},
			onSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				return spanRow({
						row,
						column,
						rowIndex,
						columnIndex
					},
					this.orderList,
					this.tableSpanOption
				);
			}
		}
	}
</script>
<style scoped>
	::v-deep.el-header {
		padding: 0px;
	}


	::v-deep.mainArea {
		width: 100%;
		height: calc(100vh - 170px);

	}

	.mainInner {
		background-image: url(../../assets/images/img_09.png);
		background-size: 100% 100%;
		padding: 35px 40px;
		box-sizing: border-box;
		height: 100%;
		width: 100%;
	}

	.cardTitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	.cardStyle {
		color: #fff;
		font-size: 18px;
		font-weight: bold;
		white-space: nowrap;
	}

	.cardStyle a {
		color: #fff;
		text-decoration: none;
		font-size: 14px;
	}

	.cardTitleStyle {
		display: flex;
		align-items: center;
		color: #fff;
	}

	.orderIcon {
		margin-right: 5px;
	}

	::v-deep.searchInput .el-input__inner {
		border: 1px solid #57606f;
		margin-left: 40px;
		width: 100%;
		background-image: url(../../assets/images/search.png);
		background-repeat: no-repeat;
		background-position: 15px center;
		background-size: 20px 20px;
		padding-left: 50px;
		background-color: transparent;
		color: #fff;
	}


	::v-deep.searchInput input::-webkit-input-placeholder {
		color: #999;
	}

	::v-deep.searchInput input::-moz-input-placeholder {
		color: #999;
	}

	::v-deep.searchInput input::-ms-input-placeholder {
		color: #999;
	}

	::v-deep.el-button.addBtn {
		background: #79f1ff;
		border: 1px solid #00aeff;
		color: #000;
		padding: 5px 10px;
		font-size: 16px;
		font-weight: bolder;
	}

	.rect {
		width: 70%;
		height: 8px;
		background: rgba(0, 183, 255, 0.23);
		border-radius: 20px;
		margin-right: 10px;
	}

	.scopeFlex {
		display: flex;
		align-items: center;
	}

	.rectInner {
		height: 8px;
		background: #79f1ff;
		border-radius: 20px;
	}

	::v-deep.el-table tr.el-table__row:hover {
		background-color: transparent;
	}

	::v-deep#app .el-table thead.is-group th.el-table__cell,
	::v-deep .el-table tr,
	::v-deep .el-table,
	.el-table__expanded-cell,
	::v-deep .el-table thead.is-group th.el-table__cell {
		background-color: transparent;
	}

	::v-deep.el-table {
		border: 1px solid #57606f;
	}

	::v-deep.el-table__body-wrapper {
		border: 1px solid #57606f;
	}

	::v-deep.el-container.is-vertical {
		height: 100%;
	}

	::v-deep.el-table .cell {
		text-align: center;
	}

	::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
		background: transparent;
	}

	::v-deep.el-table td.el-table__cell,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table th.el-table__cell.is-leaf {
		border-bottom: 1px solid #57606f;
	}

	::v-deep.el-table--border .el-table__cell,
	::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table__fixed-right-patch {
		border-right: 1px solid #57606f;
	}

	::v-deep.el-table--border::after,
	::v-deep.el-table--group::after,
	::v-deep.el-table::before {
		background-color: #57606f;
	}

	::v-deep.el-table thead {
		color: #79f1ff;
	}

	::v-deep.el-table td.el-table__cell {
		color: #fff;
	}


	::v-deep.el-table.detialTable tbody tr:hover {
		background: #02133a;
		box-shadow: 0px 0px 8px 0px #00b7ff inset;
	}

	::v-deep.dialogArea .el-dialog {
		background: #02133a;
		box-shadow: 0px 0px 8px 0px #00b7ff inset;
	}

	::v-deep.addDialog .el-dialog {
		background: transparent;
	}


	/* ::v-deep.dialogArea .el-dialog__header, */
	::v-deep.addDialog .el-dialog__header {
		display: none;
	}

	::v-deep.block .el-pagination {
		margin: 30px 0;
	}

	::v-deep.block .el-pager li {
		background: none;
		border: 1px solid #45526f;
		margin: 0 5px;
		color: #fff;
	}

	::v-deep.block .el-pager .active {
		color: #79f1ff !important;
		
	}

	::v-deep.block .el-pagination .btn-next,
	::v-deep.block .el-pagination .btn-prev {
		background: center center no-repeat #45526f;
		color: #fff;
	}

	::v-deep.block .el-input__inner {
		background-color: transparent;
		border: 1px solid #45526f;
	}

	::v-deep.editBtn {
		padding: 5px 12px;
		font-size: 16px;
		border: 1px solid #00aeff;
		background: #79f1ff;
		color: #000;
	}

	.autobtn {
		background: #79f1ff;
		padding: 10px;
		border: none;
		font-size: 14px;
		color: #000000;
	}
</style>
