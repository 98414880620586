<template>
    <div class="taggingInner">
      <div class="taggingTitle">
        转库存
        <img src="../assets/images/close.png" class="closeIcon" @click="onClose" />
      </div>
      <div class="taggingBody">
        <div class="itemCell">
          <div class="tagTitle">仓位：</div>
          <el-dropdown class="stockDropdown" trigger="click">
            <div >
              <span class="">
                {{ stockInfo.location_id ? stockInfo.warehouse_location_name : '选择入库仓位' }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </div>
  
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in locationList" :key="index" @click.native="onLocationClick(index)">{{
                item.warehouse_location_name
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="itemCell">
          <div class="tagTitle">厚度：</div>
          <el-input class="storeInput" placeholder="请录入入库厚度" v-model="stockInfo.material_thickness"></el-input>
        </div>
        <div class="itemCell">
          <div class="tagTitle">宽度：</div>
          <el-input class="storeInput" placeholder="请录入入库宽度" v-model="stockInfo.material_width"></el-input>
        </div>
        <div class="itemCell">
          <div class="tagTitle">硬度：</div>
          <el-input class="storeInput" placeholder="请录入入库硬度" v-model="stockInfo.material_hardness"></el-input>
        </div>
        <div class="itemCell">
          <div class="tagTitle">重量：</div>
          <el-input class="storeInput" placeholder="请录入入库重量" v-model="stockInfo.material_weight"></el-input>
        </div>
        <div class="buttonArea">
          <el-button class="btnCommon btnStyle2" @click="onConfirm">确认转存</el-button>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: ['selectInfo'],
    data() {
      return {
        stockInfo: {
          warehouse_id: 0,
          location_id: 0,
          material_thickness: this.selectInfo.thickness??'',
          material_width: this.selectInfo.width??'',
          material_hardness: this.selectInfo.hardness??'',
          material_weight: this.selectInfo.weight??'',
          slit_width: this.selectInfo.slit_width??''
  
        },
        locationList:[]
      }
    },
    created(){
      console.log(this.selectInfo)
      this.getLocationList();
    },
    methods: {
      onConfirm() {
        var locationId=this.stockInfo.location_id;
        if(!locationId){
          this.$message("请选择仓位");
          return;
        }
        this.$emit('onConfirmStock', this.stockInfo);
      },
      onClose() {
        this.$emit('onCloseStock', true);
      },
      getLocationList(){
        this.$scUtil.axiosPost(this, 'stockApi/GetLocationListAll', {}, res => {
          if (res.code == 100) {
            this.locationList=res.data;
          }
  
        });
      },
      onLocationClick(index){
        this.stockInfo.warehouse_id=this.locationList[index].warehouse_id;
        this.stockInfo.location_id=this.locationList[index].location_id;
        this.stockInfo.warehouse_location_name=this.locationList[index].warehouse_location_name;
      }
    }
  }
  </script>
  <style scoped>
  .taggingTitle {
    widows: 100%;
    height: 50px;
    background: #79f1ff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 18px;
    font-weight: bolder;
  }
  
  .closeIcon {
    position: absolute;
    right: 4%;
    width: 12px;
    height: 12px;
  }
  
  .taggingBody {
    background: #02133a;
    box-shadow: 0px 0px 8px 0px #00b7ff inset;
    width: inherit;
    padding: 40px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .taggingBody .itemCell {
    display: flex;
    margin-bottom: 40px;
    line-height: 40px;
    width: 100%;
    color: #fff;
    height: 40px;
  }
  
  
  .tagTitle {
    font-size: 14px;
    color: #fff;
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
  
  ::v-deep .storeInput input {
    background: none;
    border: 1px solid #57606f;
    color: #fff;
  }
  .stockDropdown {
    background: none;
    border: 1px solid #57606f;
    
  }
  
  ::v-deep .storeInput input::placeholder {
    color: #606266;
  }
  
  ::v-deep .btnCommon {
    width: 150px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 3px;
  }
  
  ::v-deep .btnStyle1 {
    border: 1px solid #00aeff;
    color: #79f1ff;
    background: transparent;
  }
  
  ::v-deep .btnStyle2 {
    border: 1px solid #00aeff;
    background: #79f1ff;
    color: #000;
  }
  
  .buttonArea {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }
  
  ::v-deep.el-button+.el-button.btnStyle2 {
    margin-left: 30px;
  }
  
  
  ::v-deep.processDropDown {
    background: #02133a;
    box-shadow: 0px 0px 8px 0px #00b7ff inset;
    display: inline-block;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  ::v-deep.processInner {
    width: 100%;
    display: block;
    border: 1px solid #788196;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 18px;
  }
  
  ::v-deep.el-icon--right {
    display: none;
  }
  
  
  ::v-deep.el-dropdown {
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    color: #fff;
  }
  
  ::v-deep.el-dropdown-menu {
    width: 600px;
    background: #02133a;
    border: 1px solid #02133a;
    box-shadow: 0px 0px 8px 0px #00b7ff inset;
    /* position: absolute!important;
      min-width: 222px;
      left: auto!important;
      right: 0; */
  }
  
  /* ::v-deep.popper__arrow{
      left: 50%!important;
  } */
  
  ::v-deep.el-dropdown-menu__item:focus,
  ::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
    background: #02133a;
    box-shadow: 0px 0px 8px 0px #00b7ff inset;
  }
  </style>
    