<template>
	<div>
		<div class="addDialogInner">
			<div class="cardTitleStyle">
				<div class="cardLine"></div>
				<span>选择物料</span>
			</div>
			<div class="searchArea">
				<img src="../assets/images/search.png" class="searchIcon" />
				<input class="inputArea" placeholder="请输入物料名称/编号/钢种" v-model="keyWord" />
				<div class="searchBtn" @click="search">查询</div>
			</div>
			<el-table class="detialTable popupTable" :row-class-name="tableRowClassName" :data="materialList"
				style="width: 100%; text-align:center">
				<el-table-column prop="material_name" label="物料名称">
				</el-table-column>
				<el-table-column prop="material_no" label="物料编号">
				</el-table-column>
				<el-table-column prop="material_type_name" label="钢种">
				</el-table-column>
				<el-table-column prop="material_width" label="物料宽度" width=100>
				</el-table-column>
				<el-table-column prop="material_thickness" label="物料厚度" width=100>
				</el-table-column>
				<el-table-column prop="material_hardness" label="物料硬度" width=100>
				</el-table-column>
				<el-table-column prop="check" label="选择" width=100>
					<template slot-scope="scope">
						<el-checkbox @change="choose(scope.$index)" v-model="scope.row.check"></el-checkbox>
					</template>
				</el-table-column>
			</el-table>
			<div class="block">
				<el-pagination @current-change="onPageIndexChange" :current-page="pageIndex"
				:page-size="pageSize"  layout="prev, pager, next" :total="totalCount"></el-pagination>
			</div>

		</div>

		<div class="buttonArea">
			<el-button class="commonBtn btnStyle1" @click="onClose">返回</el-button>
			<el-button class="commonBtn btnStyle2" @click="onConfirm">确认</el-button>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				keyWord: '',
				pageIndex: 1,
				pageSize: 10,
				totalCount: 0,
				materialList: []
			}
		},
		methods: {
			tableRowClassName(row) {
				console.log(row.row.val6);
				if (row.row.val6) {
					return 'bgkRow'
				} else {
					return ''
				}
			},
			choose(i) {
				this.materialList.forEach((item, index) => {
					if(i != index) {
						item.check = false;
					}
				})
			},
			getMaterialList() {
				this.$scUtil.axiosPost(this, 'MaterialApi/GetMaterialList', {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					keyWord: this.keyWord
				}, res => {
					if (res.code == 100) {
						res.data.forEach(item => {
							item.check = false;
						})
						this.materialList = res.data;
						this.totalCount = res.totalcount;
					}
				});
			},
			search() {
				this.pageIndex = 1;
				this.getMaterialList();
			},
			onPageIndexChange(val) {
				this.pageIndex = val;
				this.getMaterialList();
			},
			onClose() {
				this.$emit('onCloseMaterial');
			},
			onConfirm() {
				let i = 0
				this.materialList.forEach((item, index) => {
					console.log(item.check)
					if(item.check) {
						i = index;
					}
				})
				
				if(i <= 0) {
					this.onClose();
				}
				
				this.$emit('onChooseMaterial', this.materialList[i]);
			}
		},
		created() {
			this.getMaterialList();
		}
	}
</script>
<style scoped>
	::v-deep.addDialogInner {
		background: #02133a;
		padding: 20px;
		box-shadow: 0px 0px 8px 0px #00b7ff inset;
	}

	.cardTitleStyle {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #fff;
	}

	.cardLeftInner {
		display: flex;
		align-items: center;
	}

	.cardTitleStyle.between {
		justify-content: space-between;
	}

	.cardLine {
		width: 5px;
		height: 15px;
		background: #79f1ff;
		margin-right: 15px;
	}

	.commonBtn {
		width: 138px;
		height: 40px;
	}

	.btnStyle1 {
		color: #79f1ff;
		border: 1px solid #00aeff;
		border-radius: 10px;
		background: transparent;
	}

	.btnStyle2 {
		background: #79f1ff;
		border: 1px solid #00aeff;
		color: #fff;
		border-radius: 10px;
	}

	::v-deep.el-button+.el-button {
		margin-left: 40px;
	}

	.buttonArea {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}

	.searchArea {
		display: flex;
		align-items: center;
		height: 50px;
		border: 1px solid #4e5a75;
		margin-top: 20px;
	}

	.searchIcon {
		margin-left: 30px;
		width: 16px;
		height: 16px;
		margin-right: 10px
	}

	.searchBtn {
		width: 90px;
		text-align: center;
		border-left: 1px solid #0090df;
		color: #0090df;
	}

	.inputArea {
		flex: 1;
		margin-right: 15px;
		height: 30px;
		border: none;
		background: none;
		color: #fff;
	}

	::v-deep input {
		outline: none;
	}

	::v-deep.detialTable {
		margin-top: 20px;
	}

	::v-deep.el-table tr.el-table__row:hover {
		background-color: transparent;
	}

	::v-deep#app .el-table thead.is-group th.el-table__cell,
	::v-deep .el-table tr,
	::v-deep .el-table,
	.el-table__expanded-cell,
	::v-deep .el-table thead.is-group th.el-table__cell {
		background-color: transparent;
	}

	::v-deep.el-table {
		border: 1px solid #57606f;
	}

	::v-deep.el-table__body-wrapper {
		border: 1px solid #57606f;
	}

	::v-deep.el-container.is-vertical {
		height: 100%;
	}

	::v-deep.el-table .cell {
		text-align: center;
	}

	::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
		background: transparent;
	}

	::v-deep.el-table td.el-table__cell,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table th.el-table__cell.is-leaf {
		border-bottom: 1px solid #57606f;
	}

	::v-deep.el-table--border .el-table__cell,
	::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table__fixed-right-patch {
		border-right: 1px solid #57606f;
	}

	::v-deep.el-table--border::after,
	::v-deep.el-table--group::after,
	::v-deep.el-table::before {
		background-color: #57606f;
	}

	::v-deep.el-table thead {
		color: #79f1ff;
	}

	::v-deep.el-table td.el-table__cell {
		color: #fff;
	}

	::v-deep.el-table th.el-table__cell {
		background-color: transparent;
	}

	.orderDialogDetailTable {
		margin-top: 20px;
	}

	::v-deep.el-button.editBtn {
		height: 25px;
		background: #79f1ff;
		padding: 2px 5px;
		color: #000;
		border: 1px solid #00aeff;
	}

	::v-deep.orderDialogDetailTable input {
		width: 80%;
		background: transparent;
		margin: 0 auto;
		border: 1px solid #4e5a75;
		height: 25px;
		color: #fff;
		text-align: center;
	}

	::v-deep.el-table .el-table__cell {
		border-right: 1px solid #57606f;
	}

	::v-deep.el-table .cell {
		padding-left: 0;
		padding-right: 0;
	}

	::v-deep.el-table .borderNone {
		border-right: 0px solid transparent;
	}

	::v-deep.el-table .el-input__inner {
		height: 29px;
		border-radius: 0px;
	}


	::v-deep.el-table.popupTable tbody tr.bgkRow {
		background: #02133a;
		box-shadow: 0px 0px 8px 0px #00b7ff inset;
	}


	::v-deep.block {
		text-align: center;
	}

	::v-deep.block .el-pagination {
		margin: 30px 0;
	}

	::v-deep.block .el-pager li {
		background: none;
		border: 1px solid #45526f;
		margin: 0 5px;
		color: #fff;
	}
	::v-deep.block .el-pager .active {
		color: #79f1ff !important;
		
	}

	::v-deep.block .el-pagination .btn-next,
	::v-deep.block .el-pagination .btn-prev {
		background: center center no-repeat #45526f;
		color: #fff;
	}

	::v-deep.block .el-input__inner {
		background-color: transparent;
		border: 1px solid #45526f;
	}
</style>
