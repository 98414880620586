const scUtil = {
    globalData: {
        //apiServer: 'http://localhost:24471/',//develop1
        apiServer: 'https://api.jqm.0513soft.com/',//produce
        //apiServer: 'http://testapi.jqm.0513soft.com/',//test
    }
    , axiosPost(obj, url, data, callback) {

        let fullServer = this.globalData.apiServer + url;
        //alert(fullServer);
        let loginToken = localStorage.getItem('logintoken');
        obj.axios.post(fullServer, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "logintoken": loginToken
            }
        }).then(res => {
            callback(res.data);
        }).catch(error => {
            console.log(error);
        });
    }
    , axiosGet(obj, url, data, callback) {
        let fullServer = this.globalData.apiServer + url;
        let loginToken = localStorage.getItem('logintoken');
        obj.axios.get(fullServer, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "logintoken": loginToken
            }
        }).then(res => {
            callback(res.data);
        });
    }
    , dateFormatter(date, format) {
        if (!format) {
            format = 'Y-M-D h:m:s w';
        }

        var weekArray = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");

        const formatArr = ['Y', 'M', 'D', 'h', 'm', 's', 'w']
        const returnArr = []

        var year = date.getFullYear();
        let weekIndex = date.getDay();

        returnArr.push(year)
        returnArr.push(date.getMonth() + 1)
        returnArr.push(date.getDate())
        returnArr.push(date.getHours())
        returnArr.push(date.getMinutes())
        returnArr.push(date.getSeconds())
        returnArr.push(weekArray[weekIndex]);

        for (var i in returnArr) {
            format = format.replace(formatArr[i], this.formatNumber(returnArr[i]))
        }

        return format;
    },
    formatNumber(n) {
        n = n.toString()
        return n[1] ? n : '0' + n
    },
    getGuid() {
        return "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[x]/g, () =>
            Math.floor(Math.random() * 16)
                .toString(16)
                .toUpperCase()
        );
    },
    checkLogin(obj)
    {
        this.axiosPost(obj,'sysuserapi/execute?actcmd=checklogin',{},res=>{
            if(res.code!=100)
            {
                obj.$message.error('登录失效');
                obj.$router.replace({name:'Login'});
            } 
        });
    }
};

export default scUtil;