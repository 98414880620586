<template>
    <div class="pageInner">
        <div class="title">
            <img src="../../static/20230603/arrow.png" class="arrow" @click="onBack" />
            质检详情
        </div>
        <div class="cardList">
            <div class="card">
                <div class="cardTitle">
                    <div class="line"></div>生产情况
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.produce_no }}</div>
                        <div class="gray">生产编号</div>
                    </div>
                    <div class="cellTd">
                        <div>{{ taskInfo.material_type_name }}</div>
                        <div class="gray">钢种</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.process_name }}</div>
                        <div class="gray">工序</div>
                    </div>
                    <div class="cellTd">
                        <div>{{ taskInfo.equipment_name }}</div>
                        <div class="gray">设备</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.before_produce_spec }}</div>
                        <div class="gray">领料规格</div>
                    </div>
                    <div class="cellTd">
                        <div>{{ taskInfo.after_produce_spec!=''?taskInfo.after_produce_spec:'' }}</div>
                        <div class="gray">产出规格</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.before_produce_weight }}</div>
                        <div class="gray">领料重量</div>
                    </div>
                    <div class="cellTd">
                        <div>{{ taskInfo.after_produce_weight.join(",")!=''?taskInfo.after_produce_weight.join(","):'' }}</div>
                        <div class="gray">产出重量</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.work_user_no }}</div>
                        <div class="gray">工号</div>
                    </div>
                    <div class="cellTd">
                        <div>{{ taskInfo.gmt_real_end }}</div>
                        <div class="gray">日期</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.loss_weight }}</div>
                        <div class="gray">损耗</div>
                    </div>
                    <div class="cellTd">
                        <div>{{ taskInfo.yield_ratio_name }}%</div>
                        <div class="gray">成材率</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div>{{ taskInfo.remarks }}</div>
                        <div class="gray">备注</div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="cardTitle">
                    <div class="line"></div>质检情况
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <input type="text" placeholder="输入厚度" class="input" v-model="qualityInfo.thickness" />
                        <div class='small'>厚度</div>
                    </div>
                    <div class="cellTd">
                        <input type="text" placeholder="输入宽度" class="input" v-model="qualityInfo.width" />
                        <div class='small'>宽度</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <input type="text" placeholder="输入内径" class="input" v-model="qualityInfo.inside_diameter" />
                        <div class='small'>内径</div>
                    </div>
                    <div class="cellTd">
                        <input type="text" placeholder="输入外径" class="input" v-model="qualityInfo.outside_diameter" />
                        <div class='small'>外径</div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div><input type="text" placeholder="备注" class="input" v-model="qualityInfo.result" /></div>
                        <div class="small">备注</div>
                    </div>
                    
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div class="paddingBottom">抗拉强度</div>
                        <div>
                            <input type="text" class="inputStyle" v-for="(item, index) in qualityInfo.tensile_strength"
                                :key="index" v-model="qualityInfo.tensile_strength[index]" />
                        </div>
                    </div>
                </div>
                <div class="cell">
                    <div class="cellTd">
                        <div class="paddingBottom">硬度</div>
                        <div>
                            <input type="text" class="inputStyle" v-for="(item, index) in qualityInfo.hardness" :key="index"
                                v-model="qualityInfo.hardness[index]" />
                        </div>
                    </div>
                </div>
                <div class="cell" v-if="qualityInfo.id == 0">
                    <div class="cellTd">
                        <div class="paddingBottom">质检结果</div>
                        <div class="bottonArea">
                            <button class="btn red" @click="onSelectState(-1)">不合格</button>
                            <button class="btn blue" @click="onSelectState(1)">合格</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>
</template>
<script>

export default {
    data() {
        return {
            taskInfo: {
                after_produce_weight: []
            },
            isPass: 0,
            isNoPass: 0,
            qualityInfo: {
                id: 0,
                tensile_strength: [
                    '', '', '', '', ''
                ],
                hardness: [
                    '', '', '', '', '', '', ''
                ]
            }
        }
    },
    created() {
        let loginToken = localStorage.getItem('logintoken');
        if (loginToken) {
            this.$scUtil.checkLogin(this);
            //
            this.taskInfo.id = this.$route.query.taskId;
            this.qualityInfo.id = this.$route.query.testId;
            this.getTaskInfo();
            if (this.qualityInfo.id > 0) {
                this.getQualityInfo();
            }
        }
        else {
            this.$router.replace({ name: 'qualityTestLogin' });
        }

    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        getTaskInfo() {
            var data = {
                id: this.taskInfo.id
            };
            this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetQualityTaskInfo', data, res => {
                if (res.code == 100) {
                    res.data.gmt_real_end=this.$scUtil.dateFormatter(new Date(res.data.gmt_real_end), 'Y/M/D h/m');
                    this.taskInfo = res.data;
                }

            });
        },
        getQualityInfo() {
            var data = {
                id: this.qualityInfo.id
            };
            this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetQualityInfo', data, res => {
                if (res.code == 100) {
                    this.qualityInfo = res.data;
                }

            });
        },
        onSelectState(state) {
            if (state == 1) {
                this.qualityInfo.test_state = 1;
            }
            else if (state == -1) {
                this.qualityInfo.test_state = -1;
            }
            this.onQualityTest();

        },
        onQualityTest() {

            this.$confirm('是否确认提交质检?', '提示', {
                iconClass: "el-icon-question", //自定义图标样式
                confirmButtonText: "确认", //确认按钮文字更换
                cancelButtonText: "取消", //取消按钮文字更换
                showClose: true, //是否显示右上角关闭按钮
                center:true,
                customClass:"confirmClass",
                type: "warning", //提示类型  success/info/warning/error
            }).then(() => {
                var data = JSON.parse(JSON.stringify(this.qualityInfo));
                data.task_id = this.taskInfo.id;
                data.order_detail_id = this.taskInfo.order_detail_id;
                data.tensile_strength = JSON.stringify(this.qualityInfo.tensile_strength);
                data.hardness = JSON.stringify(this.qualityInfo.hardness);
                console.log(data)

                this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=qualitytest', data, res => {
                    if (res.code == 100) {
                        this.$message.success(res.message);

                        this.$router.go(-1);
                    }
                    else {
                        this.$message(res.message);
                    }

                });
            });

        }
    }
}
</script>
<style>
.confirmClass{
    width:80% !important;
}
</style>

<style scoped>


.pageInner {
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.title {
    width: 100%;
    height: 50px;
    background: #0071c9;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: relative;
}

.arrow {
    width: 10px;
    height: 21px;
    position: absolute;
    top: 30%;
    left: 15px;
}

.card {
    width: 100%;
    border-radius: 10px;
    padding: 0 20px 0 20px;
    background: #fff;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.cardList {
    padding: 20px 20px 0 20px;
    width: 100%;
    box-sizing: border-box;
}

.cardTitle {
    display: flex;
    align-items: center;
    height: 55px;
    line-height: 55px;
    border-bottom: 1px solid #dddddd;
}

.line {
    width: 5px;
    height: 15px;
    background: #0071c9;
    margin-right: 10px;
}

.cell {
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid #dddddd;
    padding: 10px 0;
    box-sizing: border-box;
}

.cell:nth-last-child(1) {
    border-bottom: none;
}

.cellTd {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #000000;
}

.cellTd div {
    line-height: 25px;
}

.gray {
    font-size: 12px;
    color: #888888;
}

.input {
    border: none;
    font-size: 14px;
    width:100%;
}

.input:focus {
    outline: none;
}

.small {
    font-size: 12px;
}

.inputStyle {
    width: calc((100% - 40px)/ 3);
    height: 25px;
    border: 1px solid #dddddd;
    margin-bottom: 10px;
}

.inputStyle {
    margin-right: 10px;
}

.inputStyle:nth-child(3n) {
    margin-right: 0px;
}

.paddingBottom {
    margin-bottom: 10px;
}

.btn {
    width: calc((100% - 15px) / 2);
    height: 45px;
    line-height: 45px;
    margin-right: 15px;
    border-radius: 10px;
}


.btn:nth-last-child(1) {
    margin-right: 0px;
}

.red {
    color: #d03a2b;
    border: 1px solid #d03a2b;
    background: transparent;
}

.blue {
    color: #44bb5d;
    border: 1px solid #44bb5d;
    background: transparent;
}

.bottonArea {
    margin-bottom: 10px;
}

.foot {
    width: 100%;
    height: 60px;
    background: #fff;
    border-top: 1px solid #dddddd;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.commit {
    width: 90%;
    height: 40px;
    line-height: 40px;
    background: #0071c9;
    color: #fff;
    border: 1px solid #0071c9;
    border-radius: 50px;
}
</style>

