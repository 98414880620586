<template>
  <div class="pageContainer">
    <el-container>
      <el-header style="height: 70px">
        <commonHead></commonHead>
      </el-header>
      <el-main>
        <el-row>
          <el-col :span="13">
            <div class="grid-content bg-purple">
              <el-row class="cardStyle cardheight1">
                <el-col :span="24">
                  <div class="grid-content bg-purple-dark">
                    <div class="cardTitle">
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_02.png" class="orderIcon" />
                        <span>订单情况</span>
                      </div>
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_03.png" class="orderMore">
                        <a href="JavaScript:void(0)" @click="toOrderDetailList">更多</a>
                      </div>
                    </div>
                    <!-- 订单 -->
                    <el-table :data="orderDetailList" style="width: 100%">
                      <el-table-column prop="order_no" label="订单号" width="87" />
                      <el-table-column prop="material_type_name" label="钢种" width="74" />
                      <el-table-column label="规格">
                        <el-table-column prop="thickness" label="厚度" width="50" />
                        <el-table-column prop="thickness_tolerance" label="公差" width="50" />
                        <el-table-column prop="width" label="宽度" width="50" />
                        <el-table-column prop="width_tolerance" label="公差" width="50" />
                      </el-table-column>
                      <el-table-column prop="order_weight" label="订货量(T)" width="63" />
                      <el-table-column label="用户要求">
                        <el-table-column prop="require_stock" label="库存" width="50" />
                        <el-table-column prop="require_knife_bend" label="刀弯" width="50" />
                        <el-table-column prop="require_roll_diameter" label="卷径" width="50" />
                        <el-table-column prop="require_other" label="其他" width="50" />
                      </el-table-column>
                      <el-table-column prop="gmt_delivery_plan" label="交货期" width="89" />
                      <el-table-column prop="order_detail_state_name" label="交货状态" width="60" />
                      <el-table-column label="生产进度" width="160">
                        <template slot-scope="scope">
                          <div class="scopeFlex"
                            v-if="scope.row.produce_percent > 0 && scope.row.readd_count == 0 && scope.row.detail_state > 0">
                            <div class="rect">
                              <div class="rectInner" :style="('width:' + scope.row.produce_percent + '%')"></div>
                            </div>
                            {{ scope.row.produce_percent + '%' }}
                          </div>
                          <div class="tdButton" v-else>
                            <el-button v-if="scope.row.craft_id == 0 && scope.row.material_id > 0"
                              @click="onChooseCraft(scope.$index)" size="mini" class="blue">工艺<br />选择</el-button>
                            <el-button v-if="scope.row.material_list.length == 0" @click="onChooseMaterial(scope.$index)"
                              size="mini" class="green">选择<br />原料</el-button>
                            <el-button v-if="scope.row.readd_count > 0" @click="onReChooseMaterial(scope.$index)"
                              size="mini" class="green">重选<br />原料</el-button>
                            <el-button
                              v-if="scope.row.detail_state == 0 && scope.row.material_list.length > 0 && scope.row.readd_count == 0"
                              @click="onProduceSchedule(scope.$index)" size="mini" class="yellow">排产<br />设置</el-button>
                            <el-button v-if="scope.row.detail_state > 0" @click="onProduceSchedule(scope.$index)"
                              size="mini" class="yellow">查看<br />排产</el-button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cardStyle cardStyle2 cardheight2 cardTop">
                <el-col :span="24">
                  <div class="grid-content bg-purple-dark">
                    <div class="cardTitle">
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_02.png" class="orderIcon" />
                        <span>排产设置</span>
                      </div>
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_03.png" class="orderMore">
                        <a href="JavaScript:void(0)" @click="toOrderMaterialList">更多</a>
                      </div>
                    </div>
                  </div>
                  <div class="autoTable flexTable">
                    <el-table :data="handleOrderDetailInfo.material_list" class="autoTableInner" style="width: 70%">
                      <el-table-column prop="produce_no" label="生产编号">
                      </el-table-column>
                      <el-table-column prop="material_no" label="钢种" width="120">
                      </el-table-column>
                      <el-table-column label="原料规格">
                        <el-table-column prop="thickness" label="厚度" width="50">
                        </el-table-column>
                        <el-table-column prop="weight" label="重量" width="60">
                        </el-table-column>
                        <el-table-column prop="hardness" label="硬度" width="55">
                        </el-table-column>
                      </el-table-column>
                      <el-table-column prop="state_name" label="交货状态" width="80">
                      </el-table-column>
                      <el-table-column label="排单" width="120" :render-header="scheduleColumnHeader">
                        <template slot-scope="scope">
                          <div class="tdButton">
                            <el-button v-if="scope.row.state == 0"
                              @click="onOrderMaterialProduceScheduleConfirm(scope.row.id)" size="mini"
                              class="autobtn">自动<br />排单</el-button>
                            <el-button v-else @click="onViewMaterialProduceTask(scope.row.id)" size="mini"
                              class="autobtn">查看<br />排单</el-button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <el-table :data="materialProduceTaskList" border style="width: 100%">
                    <el-table-column prop="process_name" label="工艺路线">
                    </el-table-column>
                    <el-table-column prop="equipment_sn" label="设备号">
                      <template slot-scope="scope">
                        <el-button class="buttonStyle1" @click="onViewEquipmentTask(scope.row.equipment_id)"
                          size="small">{{ scope.row.equipment_sn }}</el-button>
                      </template>
                    </el-table-column>
                    <el-table-column prop="gmt_delivery_plan" label="交货时间">
                    </el-table-column>
                    <el-table-column prop="gmt_plan_end" label="预计完成时间">
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注">
                    </el-table-column>
                  </el-table>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="11">
            <div class="grid-content bg-purple-light">
              <el-row class="cardStyle cardheight3">
                <el-col :span="24">
                  <div class="grid-content bg-purple-dark">
                    <div class="cardTitle">
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_02.png" class="orderIcon" />
                        <span>生产状况</span>
                        <div class="z_gray z_radius"></div>
                        <div class="z_text">待生产</div>
                        <div class="z_yellow z_radius"></div>
                        <div class="z_text">生产中</div>
                        <div class="z_green z_radius"></div>
                        <div class="z_text">已完成</div>
                        <div class="z_red z_radius"></div>
                        <div class="z_text">暂停</div>
                      </div>
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_03.png" class="orderMore">
                        <a href="JavaScript:void(0)">更多</a>
                      </div>
                    </div>

                    <!-- 生产状况（设备） -->
                    <el-table :data="equipmentList" :span-method="onSpanMethod" border style="width: 100%">
                      <el-table-column prop="type_name" label="工序" width="94">
                      </el-table-column>
                      <el-table-column prop="equipment_sn" label="设备号" width="94">
                        <template slot-scope="scope">
                          <span style="cursor:pointer;" @click="onViewEquipmentTask(scope.row.id)">{{
                            scope.row.equipment_sn }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column prop="equipment_state_name" label="设备状态" width="94">
                        <template slot-scope="scope">
                          <div class="normal_btn" v-if="scope.row.equipment_state == 10">正常</div>
                          <div class="warning_btn" v-else>检修</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="task_list" label="任务" class="rw">
                        <template slot-scope="scope">
                          <div v-for="(item, index) in scope.row.task_list" :key="index" class="r_flex">
                            <!-- -2：作废 -1：暂停 0：待生产 1=生产中 10=已完成 -->
                            <span class="z_red r_btn" :title="item.material_no" v-if="item.task_state == 0">{{ index + 1
                            }}</span>
                            <span class="r_gray r_btn" :title="item.material_no" v-if="item.task_state == 1">{{ index + 1
                            }}</span>
                            <span class="r_yellow r_btn" :title="item.material_no" v-if="item.task_state == 2">{{ index +
                              1
                            }}</span>
                            <span class="z_green r_btn" :title="item.material_no" v-if="item.task_state == 10">{{ index +
                              1
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
              <el-row class="cardStyle cardheight4">
                <el-col :span="24">
                  <div class="grid-content bg-purple-dark">
                    <div class="cardTitle">
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_02.png" class="orderIcon" />
                        <span>设备任务明细</span>
                        <div class="smallTitle" v-if="equipmentShowInfo.id">
                          <span>（ {{ equipmentShowInfo.equipment_name }}</span>
                          <span>/</span><span>{{ equipmentShowInfo.equipment_sn }}</span><span>/</span><span>状态</span>
                          <div class="titleBtn">{{ equipmentShowInfo.state_name }}</div> <span>）</span>
                        </div>
                      </div>
                      <div class="cardTitleStyle">
                        <img src="../../assets/images/img_03.png" class="orderMore">
                        <a href="JavaScript:void(0)" @click="toTaskList()">更多</a>
                      </div>
                    </div>
                    <el-table :data="equipmentProduceTaskList" border style="width: 100%">
                      <el-table-column prop="gmt_plan_begin" label="开始时间">
                      </el-table-column>
                      <el-table-column prop="produce_no" label="生产编号">
                      </el-table-column>
                      <el-table-column prop="material_type_name" label="钢种">
                      </el-table-column>
                      <el-table-column prop="thickness_tolerance" label="规格">
                      </el-table-column>
                      <el-table-column prop="width" label="宽度">
                      </el-table-column>
                      <el-table-column prop="order_weight" label="重量">
                      </el-table-column>
                      <el-table-column prop="task_state_name" label="生产状况">
                      </el-table-column>
                      <el-table-column prop="quality_test_state_name" label="质检状况">
                      </el-table-column>
                      <el-table-column prop="work_user_no" label="工号">
                      </el-table-column>
                      <!-- 操作 -->
                      <el-table-column label="操作" width="60">
                        <template slot-scope="scope">
                          <div class="tdButton">
                            <el-button v-if="scope.row.task_state == 0 || scope.row.task_state == 1"
                              @click="onInsertTaskRequest(scope.row.id, scope.row.equipment_id)" size="mini"
                              class="green btnInsert">插单</el-button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-main>

      <!-- 选择物料 -->
      <el-dialog v-if="materialDialogState" :visible.sync="materialDialogState" class="processDialogArea" width="894px">
        <meterialDialog @onDialogHide="onMaterialDialogHide" @onConfirm="onMaterialConfirm"
          :orderDetailId="handleOrderDetailInfo.id"></meterialDialog>
      </el-dialog>
      <!-- 重新选择物料 -->
      <el-dialog v-if="reMaterialDialogState" :visible.sync="reMaterialDialogState" class="processDialogArea"
        width="894px">
        <reMeterialDialog @onDialogHide="onReMaterialDialogHide" @onConfirm="onReMaterialConfirm"
          :orderDetailId="handleOrderDetailInfo.id"></reMeterialDialog>
      </el-dialog>

      <!-- 选择工艺 -->
      <el-dialog v-if="craftDialogState" :visible.sync="craftDialogState" class="processDialogArea" width="894px">
        <craftDialog @onDialogHide="onCraftDialogHide" @onCraftConfirm="onCraftConfirm"
          :orderDetailId="handleOrderDetailInfo.id"></craftDialog>
      </el-dialog>

      <!-- 插单 -->
      <el-dialog v-if="taskInsertDialogState" :visible.sync="taskInsertDialogState" class="processDialogArea"
        width="894px">
        <produceTaskDialog @onDialogHide="onInsertDialogHide" @onConfirm="onInsertTaskConfirm"
          :insertData="taskInsertInfo"></produceTaskDialog>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { spanRow } from "element-ui-table-span-method";
import CommonHead from '../../components/CommonHead.vue';
import MeterialDialog from '../../components/MaterialDialog.vue'
import ReMeterialDialog from '../../components/MaterialDialog.vue'
import CraftDialog from '../../components/CraftDialog.vue'
import produceTaskDialog from '../../components/ProduceTaskDialog.vue'
export default {
  name: 'productionManage',
  components: {
    'commonHead': CommonHead,
    "meterialDialog": MeterialDialog,
    "reMeterialDialog": ReMeterialDialog,
    'craftDialog': CraftDialog,
    'produceTaskDialog': produceTaskDialog
  },
  data() {
    return {
      craftDialogState: false,
      materialDialogState: false,
      reMaterialDialogState:false,
      taskInsertDialogState: false,
      tableSpanOption: [
        { index: 0, field: "type_name" }
      ],
      orderDetailList: [],
      handleOrderDetailInfo: {},
      materialProduceTaskList: [],
      equipmentProduceTaskList: [],
      equipmentList: [],
      equipmentShowInfo: {},
      equipmentTimer: null,
      taskInsertInfo: {}
    }
  },
  methods: {
    onInsertTaskRequest(taskId, equipmentId) {
      this.taskInsertInfo = {
        mainTaskId: taskId,
        equipmentId: equipmentId
      };
      this.onInsertDialogShow();
    },
    onInsertDialogShow() {
      this.taskInsertDialogState = true;
    },
    onInsertDialogHide() {
      this.taskInsertDialogState = false;
    },
    onInsertTaskConfirm(data) {
      let targetTaskId = data.id;
      if (!targetTaskId) {
        this.$message.warning('请至少选择一个工单');
        return;
      }

      if (targetTaskId == this.taskInsertInfo.mainTaskId) {
        this.$message.warning('不能选择自身工单插单');
        return;
      }

      let param = {
        mainTaskId: this.taskInsertInfo.mainTaskId,
        targetTaskId: targetTaskId
      };

      this.$scUtil.axiosPost(this, 'ProduceTaskApi/execute?actcmd=inserttask', param, res => {
        this.$message(res.message);
        if (res.code == 100) {
          let equipmentId = this.taskInsertInfo.equipmentId;
          if (equipmentId) {
            this.onViewEquipmentTask(equipmentId)
          }
          this.onInsertDialogHide();
          this.taskInsertInfo = {};
        }
      });
    },
    scheduleColumnHeader(h) {
      return h('div', [
        h('span', {
          style: 'cursor:pointer',
          on: {
            click: this.onOrderDetailProduceScheduleConfirm
          }
        }, '全部排产')
      ]);
    },
    //查看原材料排产计划
    onViewMaterialProduceTask(id) {
      this.$scUtil.axiosPost(this, 'producetaskapi/GetProduceTaskByOrderMaterialId', { orderMaterialId: id }, res => {
        if (res.code == 100) {
          this.materialProduceTaskList = res.data;
        }
      });
    },
    onViewEquipmentTask(id) {

      //获取设备信息
      this.$scUtil.axiosPost(this, 'EquipmentApi/GetOneById', { id: id }, res => {
        console.log(res);
        if (res.code == 100) {
          this.equipmentShowInfo = res.data;
        }
      });

      this.$scUtil.axiosPost(this, 'producetaskapi/GetNoFinishedTaskByEquipmentId', { equipmentId: id }, res => {
        if (res.code == 100) {
          console.log(res.data);
          this.equipmentProduceTaskList = res.data;
        }
      });
    },
    //选择排产
    onProduceSchedule(index) {
      this.onOrderDetailSelected(index);

      //默认显示第一个物料的排产信息
      let materialList = this.orderDetailList[index].material_list;
      if (materialList.length > 0) {
        let firstId = materialList[0].id;
        if (firstId > 0) {
          this.onViewMaterialProduceTask(firstId);
        }
      }
    },
    //整个订单明细排产
    onOrderDetailProduceScheduleConfirm() {
      if (!this.handleOrderDetailInfo.id) {
        this.$message('请先选择订单排产');
        return;
      }

      this.$confirm('是否确认排产?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {

        let param = {
          orderId: this.handleOrderDetailInfo.order_id,
          orderDetailId: this.handleOrderDetailInfo.id
        };

        this.$scUtil.axiosPost(this, 'producetaskapi/execute?actcmd=detailschedule', param, res => {
          this.$message(res.message);
          if (res.code == 100) {

            let resData = res.data;
            let index = this.handleOrderDetailInfo.index;

            this.handleOrderDetailInfo.detail_state = resData.detail_state;
            this.handleOrderDetailInfo.material_list.forEach(item => {
              item.state = resData.material_state;
            });

            //修改原订单信息
            this.orderDetailList[index].detail_state = resData.detail_state;
            this.orderDetailList[index].material_list.forEach(item => {
              item.state = resData.material_state;
            });
          }
        });
      });
    },
    //单个订单原材料排产
    onOrderMaterialProduceScheduleConfirm(id) {
      this.$confirm('是否确认排产?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        let param = {
          orderId: this.handleOrderDetailInfo.order_id,
          orderDetailId: this.handleOrderDetailInfo.id,
          orderDetailMaterialId: id
        };

        this.$scUtil.axiosPost(this, 'producetaskapi/execute?actcmd=detailmaterialschedule', param, res => {
          this.$message(res.message);
          if (res.code == 100) {

            let resData = res.data;
            let index = this.handleOrderDetailInfo.index;

            this.handleOrderDetailInfo.detail_state = resData.detail_state;
            this.handleOrderDetailInfo.material_list.forEach(item => {
              if (item.id == id) {
                item.state = resData.material_state;
              }
            });

            //修改原订单信息
            this.orderDetailList[index].detail_state = resData.detail_state;
            this.orderDetailList[index].material_list.forEach(item => {
              if (item.id == id) {
                item.state = resData.material_state;
              }
            });
          }
        });
      });
    },
    //选择原材料
    onChooseMaterial(index) {
      this.onOrderDetailSelected(index);
      this.onMaterialDialogShow();
    },
    onMaterialConfirm(data) {
      let param = {
        orderDetailId: this.handleOrderDetailInfo.id,
        array: JSON.stringify(data)
      };
      this.$scUtil.axiosPost(this, 'orderapi/execute?actcmd=savedetailmaterial', param, res => {
        this.$message(res.message);
        if (res.code == 100) {
          this.materialDialogState = false;
          let index = this.handleOrderDetailInfo.index;
          this.orderDetailList[index].material_list = res.data;
          this.onMaterialDialogHide();
        }
      });
    },
    onMaterialDialogShow() {
      this.materialDialogState = true;
    },
    onMaterialDialogHide() {
      this.materialDialogState = false;
    },
    onOrderDetailSelected(index) {
      let item = this.orderDetailList[index];
      this.handleOrderDetailInfo = JSON.parse(JSON.stringify(item));
      this.handleOrderDetailInfo.index = index;
      this.materialProduceTaskList = [];
    },
    //重新选择原材料
    onReChooseMaterial(index) {
      this.onOrderDetailSelected(index);
      this.onReMaterialDialogShow();
    },
    onReMaterialConfirm(data) {
      let param = {
        orderDetailId: this.handleOrderDetailInfo.id,
        array: JSON.stringify(data)
      };
      this.$scUtil.axiosPost(this, 'orderapi/execute?actcmd=resavedetailmaterial', param, res => {
        this.$message(res.message);
        if (res.code == 100) {
          this.reMaterialDialogState = false;
          let index = this.handleOrderDetailInfo.index;
          this.orderDetailList[index].material_list = res.data;
          this.orderDetailList[index].readd_count = 0;
          this.onReMaterialDialogHide();
        }
      });
    },
    onReMaterialDialogShow() {
      this.reMaterialDialogState = true;
    },
    onReMaterialDialogHide() {
      this.reMaterialDialogState = false;
    },

    //选择工艺
    onChooseCraft(index) {
      this.onOrderDetailSelected(index);
      this.onCraftDialogShow();
    },
    onCraftDialogShow() {
      this.craftDialogState = true;
    },
    onCraftDialogHide() {
      this.craftDialogState = false;
    },
    //工艺提交
    onCraftConfirm(craftId) {
      let orderDetailInfo = this.handleOrderDetailInfo;
      let index = orderDetailInfo.index;

      //修改工艺到订单明细
      this.$scUtil.axiosPost(this, 'orderapi/execute?actcmd=savedetailcraft', { orderDetailId: orderDetailInfo.id, craftId: craftId }, res => {
        this.$message(res.message);
        if (res.code == 100) {
          console.log(1);
        }
      });

    },
    onSpanMethod({ row, column, rowIndex, columnIndex }) {
      return spanRow(
        { row, column, rowIndex, columnIndex },
        this.equipmentList,
        this.tableSpanOption
      );
    }
    , getEquipmentList() {
      //获取设备及任务
      this.$scUtil.axiosPost(this, 'equipmentapi/GetEquipmentListWithTask', {}, res => {
        if (res.code == 100) {
          this.equipmentList = res.data;
        }
      });
    },
    toOrderDetailList() {
      window.open('/#/order/detail/list');
    },
    toOrderMaterialList() {
      window.open('/#/order/material/list');
    },
    toTaskList() {
      window.open('/#/task/list');
    }
  }
  , created() {
    let loginToken = localStorage.getItem('logintoken');
    if (loginToken) {
      this.$scUtil.checkLogin(this);

      //获取订单
      this.$scUtil.axiosPost(this, 'orderapi/GetOrderDetailPageList', { pageIndex: 1, pageSize: 8 }, res => {
        if (res.code == 100) {
          this.orderDetailList = res.data;
        }
      });

      //获取设备列表
      this.getEquipmentList();

      this.equipmentTimer = setInterval(() => {
        this.getEquipmentList();
      }, 6000);
    }
    else {
      this.$router.replace({ name: 'Login' });
    }
  }, destroyed() {
    clearInterval(this.equipmentTimer);
  }
}
</script>

<style scoped>
::v-deep.el-header {
  padding: 0px;
}

::v-deep.el-table th.el-table__cell>.cell {
  text-align: center;
}

::v-deep.el-table td.el-table__cell div {
  text-align: center;
  font-size: 13px;
  font-weight: normal;
}

::v-deep.el-table .el-table__cell {
  padding: 3px 0;
}

::v-deep.el-table {
  color: #fff;
}

::v-deep.el-table thead.is-group th.el-table__cell {
  color: #79f1ff;
  background: none;
}

::v-deep.el-table tr,
.el-table,
::v-deep.el-table__expanded-cell {
  background-color: transparent;
}

.cardStyle {
  padding: 15px;
  background-image: url(../../assets/images/img_04.png);
  background-size: 100% 100%;
}


::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;

}

::v-deep.el-table {
  border: 1px solid #57606f;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cardStyle {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.cardStyle a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.cardTitleStyle {
  display: flex;
  align-items: center;
}

.orderIcon {
  margin-right: 5px;
}

.orderMore {
  margin-right: 10px;
}

.rect {
  width: 50%;
  height: 8px;
  background: rgba(0, 183, 255, 0.23);
  border-radius: 20px;
  margin-right: 10px;
}

.scopeFlex {
  display: flex;
  align-items: center;
}

.rectInner {
  height: 8px;
  background: #79f1ff;
  border-radius: 20px;
}

.tdButton {
  display: flex;
  align-items: center;
}

::v-deep.el-button.green {
  background: #8fc31f;
  border: none;
  color: #fff;
}

::v-deep.el-button.btnInsert {
  padding: 6px;
}

::v-deep.el-button.blue {
  background: #79f1ff;
  border: none;
  color: #fff;
}

::v-deep.el-button.yellow {
  background: #fffc00;
  border: none;
  color: #000;
}

::v-deep.el-button.redBtn {
  background: #ec4848;
  border: none;
  color: #fff;
}

::v-deep.el-button--mini,
::v-deep.el-button--mini.is-round {
  padding: 2px 3px;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

.cardTop {
  margin-top: 15px;
}

.cardStyle2 {
  padding: 15px;
  background-image: url(../../assets/images/img_05.png);
  background-size: 100% 100%;
}

.buttonStyle1 {
  background: #79f1ff;
  color: #000;
  border: none;
  padding: 5px 13px;
}

.autoTable {
  width: 100%;
  background: url(../../assets/images/img_08.png);
  background-size: 100% 100%;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
}

.autobtn {
  background: #79f1ff;
  padding: 10px;
  border: none;
  font-size: 14px;
  color: #000000;
}


::v-deep.el-table thead {
  color: #79f1ff;
}

::v-deep.el-table.autoTableInner thead.is-group th.el-table__cell {
  color: #fff;
}

::v-deep.autoTableInner .el-table__empty-block {
  min-height: 25px;
}

::v-deep.autoTableInner .el-table__empty-text {
  line-height: 25px;
}

.smallTitle {
  font-size: 14px;
  font-weight: normal;
  margin-left: 15px;
}

.titleBtn {
  background: #00ff00;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  width: 34px;
  height: 18px;
  text-align: center;
  line-height: 18px;
}

.smallTitle span {
  margin-right: 10px;
}

.cardheight1,
.cardStyle2 {
  margin-right: 15px;
}

.tdButton .el-button {
  margin-left: 5px;
}

.cardheight3 {
  background-image: url(../../assets/images/img_06.png);
  background-size: 100% 100%;
  margin-bottom: 15px;
}

.cardheight4 {
  background-image: url(../../assets/images/img_07.png);
  background-size: 100% 100%;
}

.z_radius {
  width: 22px;
  height: 14px;
  border-radius: 20px;
  margin-right: 5px;
}

.z_gray {
  background: #9a9a9a;
  margin-left: 20px;
}

.r_gray {
  background: #9a9a9a;
}

.z_yellow {
  background: #fffc00;
}

.r_yellow {
  background: #fffc00;
  color: #000;
}

.z_green {
  background: #00ff00;
}

.z_red {
  background: #ff0000;
}

.z_text {
  font-size: 14px;
  font-weight: normal;
  margin-right: 20px;
}

.r_flex {
  display: inline-block;
  float: left;
  margin: 0;
  padding: 0;
  line-height: 15.4px;
}

.r_flex span {
  margin-right: 10px;
  cursor: pointer;
}

.r_btn {
  padding: 0 5px;
  border-radius: 20px;

}

.normal_btn {
  width: 34px;
  height: 23px;
  background: #00ff00;
  color: #fff;
  border-radius: 5px;
  margin: 0 auto;
}

.warning_btn {
  width: 34px;
  height: 23px;
  background: #ff0000;
  color: #fff;
  border-radius: 5px;
  margin: 0 auto;
}

.flexTable {
  display: flex;
  flex-wrap: nowrap;
}

.autoButtonArea {
  width: 70px;
  height: 90px;
  border: 1px solid #57606f;
  border-left: 0px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep.flexTable .el-table td.el-table__cell div {
  white-space: nowrap;
}

::v-deep.pageContainer .el-dialog {
  background: none;
  min-width: 1043px;
}

::v-deep.pageContainer .el-dialog .el-dialog__header {
  display: none;
}

::v-deep.pageContainer .el-dialog .el-dialog__body {
  padding: 0px;
}

::v-deep.pageContainer .processDialogArea {
  background: none;
  min-width: 894px;
}
</style>
