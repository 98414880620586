<template>
  <div class="cardTitle cardStyle cardBetween topSize">
    <div class="cardTitleStyle">
        <img src="../assets/images/img_02.png" class="orderIcon"/>
        <span>任务情况</span>
    </div>
    <div class="cardTitleStyle cardFlex">
      <div class="cardFlex">生产状况:<div class="cir gray"></div> 待生产<div class="cir yellow"></div> 生产中<div class="cir green"></div> 完成<div class="cir red"></div>作废</div>
      <div class="cardLine">/</div>
      <div class="cardFlex">质检状况: <div class="cir gray"></div>未检<div class="cir green"></div>合格<div class="cir red"></div>不合格</div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>

.cardStyle,.cardTitleStyle{
  display: flex;
  align-items: center;
  color: #fff;
  font-size:22px;
}

.cardBetween{
  justify-content: space-between;
}

.cardFlex{
  display: flex;
  align-items: center;
}

.cir{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
}

.green{
  background: #00ff00;
}

.gray{
  background: #9a9a9a;
}

.yellow{
  background: #fffc00;
}

.red{
  background: #ff0000;
}

.cardTitle{
  padding-right: 50px;
  border: 1px solid #57606f;
  border-bottom: none;
  height: 64px;
  line-height: 64px;
}

.cardLine{
  margin: 0 20px;
}



</style>
