<template>
    <el-container class="pageContainer">
        <el-header style="height: 70px">
            <commonHead></commonHead>
        </el-header>
        <el-main class="mainArea">
            <el-row style="display:flex;flex-wrap:nowrap">
                <el-col :span="10" style="margin-right:20px">
                    <div class="cardTitle cardStyle">
                        <div class="cardTitleStyle">
                            <img src="../../assets/images/img_02.png" class="orderIcon" />
                            <span>生产情况</span>
                        </div>
                    </div>
                    <div class="leftLayout">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="taName">生产编号</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.produce_no }}</div>
                                    </td>
                                    <td>
                                        <div class="taName">钢种</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.material_type_name }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="taName">工序</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.process_name }}</div>
                                    </td>
                                    <td>
                                        <div class="taName">设备号</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.equipment_sn }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="taName">领料规格</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.before_produce_spec }}</div>
                                    </td>
                                    <td>
                                        <div class="taName">产出规格</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.after_produce_spec }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="taName">领料重量</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.before_produce_weight }}</div>
                                    </td>
                                    <td>
                                        <div class="taName">产出重量</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.after_produce_weight.join(",") }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="taName">工号</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.work_user_no }}</div>
                                    </td>
                                    <td>
                                        <div class="taName">日期</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.gmt_real_end }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="taName">耗损</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.loss_weight }}</div>
                                    </td>
                                    <td>
                                        <div class="taName">成材率</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">{{ taskInfo.yield_ratio_name }}%</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="taName">备注</div>
                                    </td>
                                    <td colspan="3">
                                        <div class="taInfo">{{ taskInfo.remarks }}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </el-col>
                <el-col :span="14">
                    <div class="cardTitle cardStyle">
                        <div class="cardTitleStyle">
                            <img src="../../assets/images/img_02.png" class="orderIcon" />
                            <span>质检情况</span>
                        </div>
                    </div>
                    <div class="rightLayout">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="tdName">
                                        <div class="taName">厚度</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">
                                            <el-input class="qualuityInput" v-model="qualityInfo.thickness" />
                                        </div>
                                    </td>
                                    <td class="tdName">
                                        <div class="taName">宽度</div>
                                    </td>
                                    <td>
                                        <div class="taInfo">
                                            <el-input class="qualuityInput" v-model="qualityInfo.width" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdName">
                                        <div class="taName">内径</div>
                                    </td>
                                    <td>
                                        <div class="taInfo"><el-input class="qualuityInput"
                                                v-model="qualityInfo.inside_diameter" /></div>
                                    </td>
                                    <td class="tdName">
                                        <div class="taName">外径</div>
                                    </td>
                                    <td>
                                        <div class="taInfo"><el-input class="qualuityInput"
                                                v-model="qualityInfo.outside_diameter" /></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdName">
                                        <div class="taName">抗拉强度</div>
                                    </td>
                                    <td colspan="3">
                                        <div class="taInfo setbottom tensile_strength">
                                            <el-input class="qualuityInput"
                                                v-for="(item, index) in qualityInfo.tensile_strength" :key="index"
                                                v-model="qualityInfo.tensile_strength[index]" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdName">
                                        <div class="taName">硬度</div>
                                    </td>
                                    <td colspan="3">
                                        <div class="taInfo setbottom hardness">
                                            <el-input class="hardnessInput" v-for="(item, index) in qualityInfo.hardness"
                                                :key="index" v-model="qualityInfo.hardness[index]" />

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tdName">
                                        <div class="taName">备注</div>
                                    </td>
                                    <td colspan="3">
                                        <div class="taInfo setbottom result">
                                            <el-input class="resultInput" v-model="qualityInfo.result" />

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="buttonArea" v-if="qualityInfo.id == 0">
                            <el-button :class="isPass == 1 ? 'buttonStyle buttonStyle5' : 'buttonStyle buttonStyle1'"
                                @click="onSelectState(1)">合格</el-button>
                            <el-button :class="isNoPass == 1 ? 'buttonStyle buttonStyle4' : 'buttonStyle buttonStyle2'"
                                @click="onSelectState(-1)">不合格</el-button>

                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import commonHead from '../../components/CommonHead.vue'
export default {
    components: {
        'commonHead': commonHead
    },
    data() {
        return {
            taskInfo: {
                after_produce_weight:[]
            },
            isPass: 0,
            isNoPass: 0,
            qualityInfo: {
                id: 0,
                tensile_strength: [
                    '', '', '', '', ''
                ],
                hardness: [
                    '', '', '', '', '', '', ''
                ]
            }
        }
    },
    created() {
        let loginToken = localStorage.getItem('logintoken');
        if (loginToken) {
            this.$scUtil.checkLogin(this);
            //
            this.taskInfo.id = this.$route.query.taskId;
            this.qualityInfo.id = this.$route.query.testId;
            this.getTaskInfo();
            if (this.qualityInfo.id > 0) {
                this.getQualityInfo();
            }
        }
        else {
            this.$router.replace({ name: 'Login' });
        }

    },
    methods: {
        getTaskInfo() {
            var data = {
                id: this.taskInfo.id
            };
            this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetQualityTaskInfo', data, res => {
                if (res.code == 100) {
                    this.taskInfo = res.data;
                }

            });
        },
        getQualityInfo() {
            var data = {
                id: this.qualityInfo.id
            };
            this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetQualityInfo', data, res => {
                if (res.code == 100) {
                    this.qualityInfo = res.data;
                }

            });
        },
        onSelectState(state) {
            if (state == 1) {
                if (this.isPass == 1) {
                    this.isPass = 0;
                }
                else {
                    this.isPass = 1;
                    this.isNoPass = 0;
                    this.qualityInfo.test_state = 1;
                }
            }
            else if (state == -1) {
                if (this.isNoPass == 1) {
                    this.isNoPass = 0;
                }
                else {
                    this.isNoPass = 1;
                    this.isPass = 0;
                    this.qualityInfo.test_state = -1;
                }
            }
            this.onQualityTest();

        },
        onQualityTest() {

            this.$confirm('是否确认提交质检?', '提示', {
                iconClass: "el-icon-question", //自定义图标样式
                confirmButtonText: "确认", //确认按钮文字更换
                cancelButtonText: "取消", //取消按钮文字更换
                showClose: true, //是否显示右上角关闭按钮
                type: "warning", //提示类型  success/info/warning/error
            }).then(() => {
                var data = JSON.parse(JSON.stringify(this.qualityInfo));
                data.task_id = this.taskInfo.id;
                data.order_detail_id = this.taskInfo.order_detail_id;
                data.tensile_strength = JSON.stringify(this.qualityInfo.tensile_strength);
                data.hardness = JSON.stringify(this.qualityInfo.hardness);
                console.log(data)

                this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=qualitytest', data, res => {
                    if (res.code == 100) {
                        this.$message.success(res.message);

                        this.$router.push({ name: "qualityList" });
                    }
                    else {
                        this.$message(res.message);
                    }

                });
            });

        }
    }
}
</script>
<style scoped>
.cardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cardStyle {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
}

.cardStyle a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.cardTitleStyle {
    display: flex;
    align-items: center;
    color: #fff;
}

.orderIcon {
    margin-right: 5px;
}

::v-deep.el-header {
    padding: 0px;
}

::v-deep.mainArea {
    width: 100%;
    height: calc(100vh - 70px);
    padding: 0 !important;
}

.leftLayout {
    background-image: url(../../assets/images/img_10.png);
    background-size: 100% 100%;
    width: 100%;
    height: calc(100vh - 170px);
    padding: 20px;
    box-sizing: border-box;
}

.rightLayout {
    background-image: url(../../assets/images/img_11.png);
    background-size: 100% 100%;
    width: 100%;
    height: calc(100vh - 170px);
    padding: 20px;
    box-sizing: border-box;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    color: #fff;
    height: 100%;
}

table tr td {
    border: 1px solid #57606f;
    text-align: center;
    padding: 10px 0;
    box-sizing: border-box;
}

table tr .tdName {
    width: 20% !important;
}

table tr td .taName {
    color: #79f1ff;
    font-size: 16px;
}

table tr td .taInfo {
    color: #fff;
    font-size: 14px;
}

.rightLayout table {
    height: 87%;
}

.buttonArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 15px;
}

::v-deep.el-button.buttonStyle {
    font-size: 16px;
    height: 50px;
}

::v-deep.el-button.buttonStyle1 {
    width: 450px;
    border: 1px solid #00ff00;
    color: #00ff00;
    background: transparent;
}

::v-deep.el-button.buttonStyle5 {
    width: 450px;
    border: 1px solid #00ff00;
    color: #fff;
    background: #00ff00;
}

::v-deep.el-button.buttonStyle2 {
    width: 450px;
    border: 1px solid #ff0000;
    color: #ff0000;
    background: transparent;
}

::v-deep.el-button.buttonStyle4 {
    width: 450px;
    border: 1px solid #ff0000;
    color: #fff;
    background: #ff0000;
}

::v-deep.el-button.buttonStyle3 {
    width: 300px;
    border: 1px solid #00aeff;
    color: #000;
    background: #79f1ff;
}

::v-deep.el-input.qualuityInput input {
    background: transparent;
    border: 1px solid #57606f;
    flex: 1;
    height: 100%;
    margin: 0 40px;
    box-sizing: border-box;
    border-radius: 0px;
    color: #fff;
}

::v-deep.el-input.hardnessInput input {
    background: transparent;
    border: 1px solid #57606f;
    flex: 1;
    height: 100%;
    margin: 0 40px;
    box-sizing: border-box;
    border-radius: 0px;
    color: #fff;
}

::v-deep.el-input.qualuityInput {
    display: flex;
}

::v-deep.el-input.hardnessInput {
    display: flex;
}

.rightLayout .taInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    height: 100%;
}

/* ::v-deep.setbottom  .el-input.qualuityInput:nth-last-child(1) input,
::v-deep .setbottom .el-input.qualuityInput:nth-child(3n + 3) input{
    margin-right: 0; */
/* } */



::v-deep.setbottom .el-input.qualuityInput {
    width: calc(100% / 3);
    margin-bottom: 10px;
}

::v-deep.setbottom .el-input.qualuityInput input {
    width: 100%;
}

::v-deep.setbottom .el-input.hardnessInput:nth-child(1) input {
    
}

::v-deep.setbottom .el-input.hardnessInput {
    width: calc(100% / 4);
    margin-bottom: 10px;
}

::v-deep.setbottom .el-input.hardnessInput input {
    width: 100%;
}

::v-deep.el-input.resultInput input {
    background: transparent;
    border: 1px solid #57606f;
    flex: 1;
    height: 100%;
    margin: 0 40px;
    box-sizing: border-box;
    border-radius: 0px;
    color: #fff;
}

::v-deep.setbottom .el-input.resultInput input {
    width: 90%;
}
</style>
