<template>
  <div class="dialogInner">
    <div class="title">
      <div class="titleText">
        <div class="titleLine"></div>原材料选择
      </div>
      <img src="../assets/images/close.png" class="closeIcon" @click="onDialogHide" />
    </div>
    <div class="searchArea">
      <div class="searchLeft">
        <img src="../assets/images/search.png" class="searchIcon" />
        <input type="text" placeholder="搜索..." v-model="param.searchText">
      </div>
      <div class="searchBtn" @click="onSearch">查询</div>
    </div>
    <el-table class="popupTable" :row-class-name="tableRowClassName"
      :data="produceTaskList.slice((param.pageIndex - 1) * param.pageSize, param.pageIndex * param.pageSize)"
      style="width:100%">
      <el-table-column prop="gmt_plan_begin" label="开始时间">
      </el-table-column>
      <el-table-column prop="produce_no" label="生产编号">
      </el-table-column>
      <el-table-column prop="material_no" label="钢种">
      </el-table-column>
      <el-table-column prop="thickness_tolerance" label="规格">
      </el-table-column>
      <el-table-column prop="width" label="宽度">
      </el-table-column>
      <el-table-column prop="order_weight" label="重量">
      </el-table-column>
      <el-table-column prop="task_state_name" label="生产状况">
      </el-table-column>
      <el-table-column prop="quality_test_state_name" label="质检状况">
      </el-table-column>
      <el-table-column prop="work_user_no" label="工号">
      </el-table-column>
      <el-table-column label="选择" prop="checked">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checked" @change="onTaskChecked(scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination align="center" @size-change="onPageSizeChange" @current-change="onPageIndexChange"
        :current-page="param.pageIndex" :page-sizes="[1, 5, 8, 10, 20]" :page-size="param.pageSize"
        layout="total, sizes, prev, pager,next,jumper" :total="total"></el-pagination>
    </div>
    <div class="btnArea">
      <el-button class="commonBtn btnStyle1" @click="onReset">重置</el-button>
      <el-button class="commonBtn btnStyle2" @click="onConfirm">确认提交</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    insertData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      produceTaskList: [],
      selectItem:{},
      param: {
        pageIndex: 1,
        pageSize: 8,
        searchText: ''
      },
      total: 0, //总条数
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onTaskChecked(index) {
      let checkItem = this.produceTaskList[index];
      let checkState = checkItem.checked;

      if (checkState == true) {
        this.selectItem=checkItem;
      }
      else {
        this.selectItem={};
      }
    },
    onSearch() {
      this.param.pageIndex = 1;
      this.produceTaskList = [];
      this.getData();
    },
    onPageSizeChange(val) {
      this.param.pageSize = val;
      this.getData();
    },
    onPageIndexChange(val) {
      this.param.pageIndex = val;
      this.getData();
    },
    getData() {
      
      this.param.equipmentId=this.insertData.equipmentId;     
        
      this.$scUtil.axiosPost(this, 'producetaskapi/GetNoFinishedTaskByEquipmentId', this.param, res => {
        console.log(res);
        if (res.code == 100) {
          res.data.forEach(item => {
            item.checked = false;
          });
          this.produceTaskList = res.data;
          this.total = res.totalcount;
        }
      });

    },
    onReset() {
      this.param.searchText = '';
      this.selectList = [];
      this.onSearch();
    },
    onConfirm() {
      if (!this.selectItem.id) {
        this.$message('请至少选择一个工单');
        return;
      }
      this.$confirm('是否确认插单?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        this.$emit('onConfirm', this.selectItem);
      });

    },
    onDialogHide() {
      this.$emit('onDialogHide');
    },
    tableRowClassName(row) {
      if (row.row.checked) {
        return 'bgkRow'
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.dialogInner {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
  padding: 30px 35px 35px 35px;
}

.title {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: bolder;

}

.titleText {
  display: flex;
  align-items: center;
}

.titleLine {
  width: 5px;
  height: 20px;
  background: #00b7ff;
  margin-right: 10px;
}

.searchBtn {
  width: 60px;
  height: 25px;
  border: 1px solid #79f1ff;
  font-size: 16px;
  color: #79f1ff;
  background: transparent;
  border-radius: 50px;
  text-align: center;
  line-height: 25px;
  margin-left: 20px;
  cursor: pointer;
}

.closeIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.searchArea {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.searchLeft {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

.searchLeft input {
  flex: 1;
  background: transparent;
  border: 0px solid transparent;
  height: 100%;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
}

::v-deep.searchLeft input[type=text]:focus {
  outline: 0px solid transparent;
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

::v-deep input::-webkit-input-placeholder {
  color: #999;
}

::v-deep input::-moz-input-placeholder {
  color: #999;
}

::v-deep input::-ms-input-placeholder {
  color: #999;
}

.btnArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonBtn {
  width: 138px;
  height: 40px;
  cursor: pointer;
}

.btnStyle1 {
  color: #79f1ff;
  border: 1px solid #00aeff;
  border-radius: 10px;
  background: transparent;
}

.btnStyle2 {
  background: #79f1ff;
  border: 1px solid #00aeff;
  color: #fff;
  border-radius: 10px;
  margin-left: 30px;
}

::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;
}

::v-deep.el-table {
  border: 1px solid #57606f;
  margin-top: 30px;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

::v-deep.block .el-pagination {
  margin: 30px 0;
}

::v-deep.block .el-pager li {
  background: none;
  border: 1px solid #45526f;
  margin: 0 5px;
  color: #fff;
}

::v-deep.block .el-pager .active {
  color: #79f1ff !important;
  
}

::v-deep.block .el-pagination .btn-next,
::v-deep.block .el-pagination .btn-prev {
  background: center center no-repeat #45526f;
  color: #fff;
}

::v-deep.block .el-input__inner {
  background-color: transparent;
  border: 1px solid #45526f;
}

::v-deep.el-table.popupTable tbody tr.bgkRow {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-button+.el-button {
  margin-left: 30px;
}
</style>
