<template>
  <div class="dialogInner">
    <div class="title">
      <div class="titleText">
        <div class="titleLine"></div>原材料选择
      </div>
      <img src="../assets/images/close.png" class="closeIcon" @click="onDialogHide" />
    </div>
    <div class="searchArea">
      <div class="searchLeft">
        <img src="../assets/images/search.png" class="searchIcon" />
        <input type="text" placeholder="搜索(仓库、仓位、生产编号等)..." v-model="param.searchText">
      </div>
      <div class="searchBtn" @click="onSearch">查询</div>
    </div>
    <el-table class="popupTable" :row-class-name="tableRowClassName" :data="inventoryList" style="width:100%">
      <el-table-column label="编号" prop="material_produce_no"></el-table-column>
      <el-table-column label="宽度" prop="material_width"></el-table-column>
      <el-table-column label="厚度" prop="material_thickness"></el-table-column>
      <el-table-column label="硬度" prop="material_hardness"></el-table-column>
      <el-table-column label="重量" prop="material_weight"></el-table-column>
      <el-table-column label="仓库" prop="warehouse_name"></el-table-column>
      <el-table-column label="仓位" prop="location_name"></el-table-column>
      <el-table-column label="分条数量" prop="slit_number">
        <template slot-scope="scope">
          <el-input class="tableInnerInput" v-model="scope.row.slit_number"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="分条宽度" prop="slit_width">
        <template slot-scope="scope">
          <el-input class="tableInnerInput" v-model="scope.row.slit_width"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="选择" prop="checked">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.checked" @change="onMaterialChecked(scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination align="center" @size-change="onPageSizeChange" @current-change="onPageIndexChange"
        :current-page="param.pageIndex" :page-sizes="[1, 5, 8, 10, 20]" :page-size="param.pageSize"
        layout="total, sizes, prev, pager,next,jumper" :total="total"></el-pagination>
    </div>
    <div class="btnArea">
      <el-button class="commonBtn btnStyle1" @click="onReset">重置</el-button>
      <el-button class="commonBtn btnStyle2" @click="onConfirm">确认提交</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderDetailId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inventoryList: [],
      selectList: [],
      param: {
        pageIndex: 1,
        pageSize: 8,
        searchText: ''
      },
      total: 0, //总条数
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onMaterialChecked(index) {
      let checkItem = this.inventoryList[index];
      let checkState = checkItem.checked;

      if (checkState == true) {
        let exists = false;
        this.selectList.forEach(item => {
          if (item.id == checkItem.id) {
            exists = true;
          }
        });

        if (!exists) {
          this.selectList.push({ id: checkItem.id,slit_number: checkItem.slit_number,slit_width:checkItem.slit_width});
        }
      }
      else {
        this.selectList.forEach((item, index) => {
          if (item.id == checkItem.id) {
            this.selectList.splice(index, 1);
          }
        });
      }

      console.log(this.selectList);

    },
    onSearch() {
      this.param.pageIndex = 1;
      this.inventoryList = [];
      this.getData();
    },
    onPageSizeChange(val) {
      this.param.pageSize = val;
      this.getData();
    },
    onPageIndexChange(val) {
      this.param.pageIndex = val;
      this.getData();
    },
    getData() {
      this.$scUtil.axiosPost(this, 'StockApi/GetInventoryPageList', this.param, res => {
        if (res.code == 100) {
          res.data.forEach(item => {
            let checkState = false;

            this.selectList.forEach(sel => {
              if (sel.id == item.id) {
                checkState = true;
              }
            });

            item.checked = checkState;
            item.slit_number = 1;
            item.slit_width = item.material_width;
          });
          this.inventoryList = res.data;
          this.total = res.totalcount;
        }
      });

    },
    onReset() {
      this.param.searchText = '';
      this.selectList = [];
      this.onSearch();
    },
    onConfirm() {
      let list = this.selectList;
      if (list.length == 0) {
        this.$message('请至少选择一件原材料');
        return;
      }
      this.$confirm('是否确认选择?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        this.$emit('onConfirm', list);
      });

    },
    onDialogHide() {
      this.$emit('onDialogHide');
    },
    tableRowClassName(row) {
      if (row.row.checked) {
        return 'bgkRow'
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
.dialogInner {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
  padding: 30px 35px 35px 35px;
}

.title {
  width: 100%;
  height: 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 18px;
  font-weight: bolder;

}

.titleText {
  display: flex;
  align-items: center;
}

.titleLine {
  width: 5px;
  height: 20px;
  background: #00b7ff;
  margin-right: 10px;
}

.searchBtn {
  width: 60px;
  height: 25px;
  border: 1px solid #79f1ff;
  font-size: 16px;
  color: #79f1ff;
  background: transparent;
  border-radius: 50px;
  text-align: center;
  line-height: 25px;
  margin-left: 20px;
  cursor: pointer;
}

.closeIcon {
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.searchArea {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.searchLeft {
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}

.searchLeft input {
  flex: 1;
  background: transparent;
  border: 0px solid transparent;
  height: 100%;
  margin-left: 20px;
  color: #fff;
  font-size: 16px;
}

::v-deep.searchLeft input[type=text]:focus {
  outline: 0px solid transparent;
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

::v-deep input::-webkit-input-placeholder {
  color: #999;
}

::v-deep input::-moz-input-placeholder {
  color: #999;
}

::v-deep input::-ms-input-placeholder {
  color: #999;
}

.btnArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonBtn {
  width: 138px;
  height: 40px;
  cursor: pointer;
}

.btnStyle1 {
  color: #79f1ff;
  border: 1px solid #00aeff;
  border-radius: 10px;
  background: transparent;
}

.btnStyle2 {
  background: #79f1ff;
  border: 1px solid #00aeff;
  color: #000;
  border-radius: 10px;
  margin-left: 30px;
}

::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;
}

::v-deep.el-table {
  border: 1px solid #57606f;
  margin-top: 30px;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

::v-deep.block .el-pagination {
  margin: 30px 0;
}

::v-deep.block .el-pager li {
  background: none;
  border: 1px solid #45526f;
  margin: 0 5px;
  color: #fff;
}

::v-deep.block .el-pager .active {
  color: #79f1ff !important;
  
}

::v-deep.block .el-pagination .btn-next,
::v-deep.block .el-pagination .btn-prev {
  background: center center no-repeat #45526f;
  color: #fff;
}

::v-deep.block .el-input__inner {
  background-color: transparent;
  border: 1px solid #45526f;
}

::v-deep.el-table.popupTable tbody tr.bgkRow {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-button+.el-button {
  margin-left: 30px;
}

::v-deep.tableInnerInput input {
  background: transparent;
  border: 1px solid #57606f;
  text-align: center;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
</style>
