<template>
    <div class="page">
        <div class="title">登录</div>
        <div class="welcomeText">
            <div class="gray">欢迎来到</div> 江勤美金属制品质检系统
        </div>
        <input type="text" class="username" v-model="form.loginName" placeholder="输入登录名称" />
        <input type="password" class="password" v-model="form.loginPwd" placeholder="输入密码" />
        <div class="remeberMe">
            <input type="checkbox" v-model="form.rememberPassword" />
            <div>记住账号密码</div>
        </div>
        <button class="loginBtn" @click="onLogin">登录</button>
        <div class="tip">南通商策信息科技 提供技术支持</div>
    </div>
</template>
<script>
export default ({
    name: "LoginMobile",
    data() {
        return {
            form: {
                loginName: '',
                loginPwd: '',
                rememberPassword: ''
            },
        }
    },
    created() {
        let loginInfoStr = localStorage.getItem('loginInfoQuality');
        if (loginInfoStr) {
            let loginInfo = JSON.parse(loginInfoStr);
            this.form = loginInfo;
        }
    },
    methods: {
        onLogin() {
            if (!this.form.loginName) {
                this.$message('登录名称不能为空');
                return;
            }

            if (!this.form.loginPwd) {
                this.$message('登录密码不能为空');
                return;
            }
            console.log(this.form)
            this.$scUtil.axiosPost(this, 'sysuserapi/execute?actcmd=qualitylogin', this.form, res => {
                if (res.code == 100) {
                    let loginToken = res.data.login_token;
                    localStorage.setItem('logintoken', loginToken);
                    if (this.form.rememberPassword) {
                        localStorage.setItem('loginInfoQuality', JSON.stringify(this.form));
                    }
                    else {
                        localStorage.removeItem('loginInfoQuality');
                    }
                    this.$router.push({ name: "qualityTestList" });
                }
                else {
                    this.$message(res.message);
                }
            });
        }
    }
})
</script>
<style scoped>
.page {
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 200px;
    box-sizing: border-box;
}

.title {
    font-size: 26px;
    color: #222222;
    width: 65%;
    text-align: left;
}

.welcomeText {
    font-size: 16rpx;
    color: #000;
    display: flex;
    align-items: center;
    margin-top: 20px;
    width: 65%;
    text-align: left;
    white-space: nowrap;
}

.gray {
    color: #686868;
    margin-right: 10px;
}

.username,
.password {
    width: 65%;
    height: 30px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.username {
    margin-top: 50px;
}

.password {
    margin-top: 20px;
}

input:focus {
    outline: none;
}

input::-webkit-input-placeholder {
    color: #cccccc
}

.remeberMe {
    width: 65%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 12px;
    color: #686868;
}

.loginBtn {
    width: 65%;
    background: #0071c9;
    color: #fff;
    font-size: 16px;
    font-weight: bolder;
    height: 40px;
    border: none;
    margin-top: 40px;
}

.tip {
    font-size: 12px;
    color: #888888;
    position: absolute;
    bottom: 5%;
}
</style>