<template>
  <el-container class="pageContainer">
    <el-header style="height: 70px">
      <commonHead></commonHead>
    </el-header>
    <el-main class="mainArea">
      <div class="mainInner">
        <div class="equipmentArea">
          <productionDetail :equipmentSn="this.equipmentInfo.equipment_sn" :processName="'倒卷'"></productionDetail>
        </div>
        <tableTitle></tableTitle>
        <el-table class="detialTable" :data="taskInfo.list" :row-class-name="tableRowClassName" style="width:100%">
          <el-table-column prop="produce_no" label="生产编号" width="200" />
          <el-table-column prop="material_type_name" label="钢种" width="120" />
          <el-table-column prop="before_produce_spec" label="原料规格" width="80" />
          <el-table-column prop="before_produce_weight" label="原料重量" width="80" />
          <el-table-column prop="customer_message" label="客户要求" width="120" />
          <el-table-column prop="after_produce_thickness" label="厚度" width="100">
            <template slot-scope="scope">
              <el-input class="tableInnerInput" v-model="scope.row.after_produce_thickness"
                :readonly="scope.row.is_edit == 1 ? false : true"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="after_produce_width" label="宽度" width="100">
            <template slot-scope="scope">
              <el-input class="tableInnerInput" v-model="scope.row.after_produce_width"
                :readonly="scope.row.is_edit == 1 ? false : true"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="after_produce_hardness" label="硬度" width="100">
            <template slot-scope="scope">
              <el-input class="tableInnerInput" v-model="scope.row.after_produce_hardness"
                :readonly="scope.row.is_edit == 1 ? false : true"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="after_produce_weight" label="重量" width=300>
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.after_produce_weight" :key="index">
                <el-input class="weightInput" 
                v-model="scope.row.after_produce_weight[index]"
                :readonly="scope.row.is_edit == 1 ? false : true"></el-input>
                <span class="inputSpan"></span>
              </span>
              
            </template>
          </el-table-column>
          <el-table-column prop="loss_weight" label="损耗" width="80">
          </el-table-column>
          <el-table-column prop="yield_ratio_name" label="成材率" width="110">
            <template slot-scope="scope">
              {{ scope.row.yield_ratio_name }}%
            </template>
          </el-table-column>
          <el-table-column prop="remarks" label="备注">
            <template slot-scope="scope">
              <el-input class="tableInnerInput" v-model="scope.row.remarks"
                :readonly="scope.row.is_edit == 1 ? false : true"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="task_state" label="生产" width="60" class="cellheight">
            <template slot-scope="scope">

              <div v-if="scope.row.task_state == 3 || scope.row.task_state == 10 || scope.row.task_state == -2"
                class="green cir cirSize"></div>
              <div v-else-if="scope.row.task_state == 0 || scope.row.task_state == 1" class="gray cir cirSize"></div>
              <div v-else-if="scope.row.task_state == 2" class="yellow cir cirSize"></div>
              <div v-else-if="scope.row.task_state == -1" class="red cir cirSize"></div>
              <div v-else-if="scope.row.task_state == 4" class="bzText">挂起</div>
            </template>
          </el-table-column>
          <el-table-column prop="test_state" label="质检" width="60" class="cellheight">
            <template slot-scope="scope">
              <div
                :class="scope.row.test_state == 1 ? 'green  cir cirSize ' : scope.row.test_state == -1 ? 'red  cir cirSize ' : 'gray  cir cirSize '">
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="bzBtn markBtn" @click="onShowMark(scope.row)"
                v-if="scope.row.is_can_produce == 1 && (scope.row.task_state == 0 || scope.row.task_state == 1 || scope.row.task_state == 2 || scope.row.task_state == 4)">
                标注</div>
              <el-button class="editBtn" v-if="scope.row.task_state == 3 && scope.row.is_edit == 0&&scope.row.is_can_save == 1"
                @click="onEdit(scope.row.id, scope.row.index)">修改</el-button>
              <el-button class="editBtn" v-if="scope.row.task_state == 3 && scope.row.is_edit == 1&&scope.row.is_can_save == 1"
                @click="onSave(scope.row.index)">保存</el-button>
              <el-button class="editBtn produceBtn" v-if="scope.row.task_state == -2"
                @click="onRollBack(scope.row.id)">任务重做</el-button>
              <el-button class="editBtn produceBtn" v-if="scope.row.task_state == -2"
                @click="onProduce(scope.row.id)">重新排产</el-button>
              <el-button class="editBtn produceBtn" v-if="scope.row.task_state == -1"
                @click="onShowStockIn(scope.row, 2)">转库存</el-button>
              <el-button class="editBtn produceBtn" v-if="scope.row.task_state == 10 && scope.row.is_last == 1"
                @click="onShowStockIn(scope.row, 1)">入库</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block pageBlock">
          <el-pagination align="center" @size-change="onPageSizeChange" @current-change="onPageIndexChange"
            :current-page="taskInfo.param.pageIndex" :page-sizes="[3, 6,18,50]" :page-size="taskInfo.param.pageSize"
            layout="total, sizes, prev, pager,next,jumper" :total="taskInfo.totalCount"></el-pagination>
        </div>
      </div>
    </el-main>
    <!-- 标注确认 -->
    <el-dialog v-if="showMark" :visible.sync="showMark" class="dialogArea" width="25%">
      <taggingMark :title="markConfirm.title" :content="markConfirm.content" :confirmText="markConfirm.confirmText" :stateShow="selectInfo.task_state"
        @onConfirmMark="onConfirmMark" @onCloseMark="onCloseMark" @onShowStock="onShowStock"></taggingMark>
    </el-dialog>
    <!-- 修改框 -->
    <el-dialog :visible.sync="showEdit" class="dialogArea" width="894px" height="500px" v-if="selectInfo">
      <editTaskProduceDialog :selectInfo="selectInfo" @onConfirmEdit="onConfirmEdit" @onCloseEdit="onCloseEdit">
      </editTaskProduceDialog>
    </el-dialog>
    <!-- input修改 -->
    <el-dialog v-if="showInput" :visible.sync="showInput" class="dialogArea" width="25%">
      <taggingConfirm :title="editConfirm.title" :content="editConfirm.content" :confirmText="editConfirm.confirmText"
        @onConfirmTagging="onConfirmInput" @onCloseTagging="onCloseInput"></taggingConfirm>
    </el-dialog>
    <!-- 转库存v1 -->
    <el-dialog v-if="showStock" :visible.sync="showStock" class="dialogArea" width="894px">
      <stockDialog :title="editConfirm.title" :content="editConfirm.content" :confirmText="editConfirm.confirmText"
        :selectInfo="selectInfo" @onConfirmStock="onConfirmStock" @onCloseStock="onCloseStock" @onBackMark="onBackMark">
      </stockDialog>
    </el-dialog>
    <!-- 转库存v2 -->
    <el-dialog v-if="showStockIn" :visible.sync="showStockIn" class="dialogArea" width="894px">
      <stockInDialog :title="editConfirm.title" :content="editConfirm.content" :confirmText="editConfirm.confirmText"
        :selectInfo="selectInfo" @onConfirmStock="onConfirmStockIn" @onCloseStock="onCloseStockIn">
      </stockInDialog>
    </el-dialog>

  </el-container>
</template>
<script>
import commonHead from '../../components/CommonHead.vue'
import taggingConfirm from '../../components/ConfirmTagging.vue'
import taggingMark from '../../components/MarkTagging.vue'
import productionDetail from '../../components/ProductionDetail.vue'
import tableTitle from '../../components/TableTitle.vue'
import editTaskProduceDialog from '../../components/EditTaskProduceDialog.vue'
import stockDialog from '../../components/StockDialog.vue'
import stockInDialog from '../../components/StockInDialog.vue'

export default {
  components: {
    'commonHead': commonHead,
    'taggingConfirm': taggingConfirm,
    'taggingMark': taggingMark,
    'productionDetail': productionDetail,
    'tableTitle': tableTitle,
    'editTaskProduceDialog': editTaskProduceDialog,
    'stockDialog': stockDialog,
    'stockInDialog': stockInDialog
  },
  data() {
    return {
      dialogType: 1,
      showMark: false,
      showEdit: false,
      showInput: false,
      showStock: false,
      showStockIn: false,
      equipmentInfo: {},
      userInfo: {},
      taskInfo: {
        totalCount: 0,
        list: [],
        param: {
          pageIndex: 1,
          pageSize: 6
        }
      },
      selectInfo: {},
      markConfirm: {
        title: '生产状况标注',
        content: '是否确认完成',
        confirmText: '确认'
      },
      editConfirm: {
        title: '提示',
        content: '是否确认修改',
        confirmText: '确认'
      },
    }
  },
  created() {
    let loginToken = localStorage.getItem('logintoken');
    if (loginToken) {
      this.$scUtil.checkLogin(this);
      //
      this.equipmentInfo.equipment_sn = this.$route.query.sn;
      this.getUserInfo();
      this.getEquipmentInfo();
      this.taskTimer = setInterval(() => {
        this.getTaskList();
      }, 60000);
    }
    else {
      this.$router.replace({ name: 'Login' });
    }

  },
  destroyed() {
    clearInterval(this.taskTimer);
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    onEdit(id, index) {
      clearInterval(this.taskTimer);
      this.taskInfo.list.forEach((item, idx) => {
        item.is_edit = 0;
      })
      this.taskInfo.list[index].is_edit = 1;
    },
    onSave(index) {
      this.taskTimer = setInterval(() => {
        this.getTaskList();
      }, 60000);
      this.$confirm('是否确认保存修改?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        var data = JSON.parse(JSON.stringify(this.taskInfo.list[index]));
        data.after_produce_weight = JSON.stringify(data.after_produce_weight);
        this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=edittask', data, res => {
          if (res.code == 100) {
            this.$message.success(res.message);
          }
          else {
            this.$message(res.message);
          }
          this.getTaskList();
        });
      });

    },
    onRollBack(id) {
      this.$confirm('是否确认修改?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        var data = {
          id: id,
          taskState: 1
        };
        this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=marktask', data, res => {
          if (res.code == 100) {
            this.$message.success(res.message);
          }
          else {
            this.$message(res.message);
          }
          this.getTaskList();
        });
      });

    },
    onProduce(id) {
      this.$confirm('是否确认重新排产?', '提示', {
        iconClass: "el-icon-question", //自定义图标样式
        confirmButtonText: "确认", //确认按钮文字更换
        cancelButtonText: "取消", //取消按钮文字更换
        showClose: true, //是否显示右上角关闭按钮
        type: "warning", //提示类型  success/info/warning/error
      }).then(() => {
        var data = {
          id: id
        };
        this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=producetask', data, res => {
          if (res.code == 100) {
            this.$message.success(res.message);
          }
          else {
            this.$message(res.message);
          }
          this.getTaskList();
        });
      });

    },


    onShowMark(row) {
      if (row.task_state != 0 && row.task_state != 1 && row.task_state != 2 && row.task_state != 4) {
        this.$message("当前状态不可修改");
        return;
      }
      this.showMark = true;
      this.selectInfo.id = row.id;
      this.selectInfo.thickness = row.after_produce_thickness;
      this.selectInfo.width = row.after_produce_width;
      this.selectInfo.hardness = row.after_produce_hardness;
      this.selectInfo.weight = row.after_produce_weight;
      this.selectInfo.task_state = row.task_state;
    },
    onCloseMark() {
      this.showMark = false;
    },
    onConfirmMark(e) {

      var data = {
        id: this.selectInfo.id,
        taskState: e.taskState
      };
      this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=marktask', data, res => {
        if (res.code == 100) {
          this.$message.success(res.message);
        }
        else {
          this.$message(res.message);
        }
        this.onCloseMark();
        this.getTaskList();
      });
    },
    onShowEdit(id, state, thickness, width, hardness, weight, remarks) {
      if (state != 0 && state != 1 && state != 2) {
        this.$message("当前状态不可修改");
        return;
      }

      this.showEdit = true;
      this.selectInfo.id = id;
      this.selectInfo.thickness = thickness;
      this.selectInfo.width = width;
      this.selectInfo.hardness = hardness;
      this.selectInfo.weight = weight;
      this.selectInfo.remarks = remarks;
      console.log(this.selectInfo)
    },
    onCloseEdit() {
      this.showEdit = false;
      this.selectInfo = {};
    },
    onConfirmEdit(e) {
      var data = {
        id: this.selectInfo.id,
        afterProduceThickness: e.thickness,
        afterProduceWidth: e.width,
        afterProduceWeight: e.weight,
        remarks: e.remarks,
      };
      this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=edittask', data, res => {
        if (res.code == 100) {
          this.$message.success(res.message);
        }
        else {
          this.$message(res.message);
        }
        this.onCloseEdit();
        this.getTaskList();
      });
    },

    getUserInfo() {
      this.$scUtil.axiosPost(this, 'sysUserApi/GetUserByToken', {}, res => {
        if (res.code == 100) {
          this.userInfo = res.data;
        }

      });
    },
    getEquipmentInfo() {

      this.$scUtil.axiosPost(this, 'EquipmentApi/GetOneBySn', { equipmentSn: this.equipmentInfo.equipment_sn }, res => {
        if (res.code == 100) {
          this.equipmentInfo = res.data;
          this.taskInfo.param.equipmentId = res.data.id;
          this.getTaskList();
        }

      });
    },
    onPageSizeChange(val) {
      this.currentPage = 1;
      this.taskInfo.param.pageSize = val;
      this.getTaskList();
    },
    onPageIndexChange(val) {
      this.taskInfo.param.pageIndex = val;
      this.getTaskList();
    },
    getTaskList() {
      this.$scUtil.axiosPost(this, 'ProduceTaskApi/GetTaskListByEquipment', this.taskInfo.param, res => {
        if (res.code == 100) {
          res.data.forEach((item) => {
            if (item.after_produce_weight.length < 3) {
              item.after_produce_weight = [0, 0, 0];
            }
            item.is_edit = 0;
          });
          this.taskInfo.list = res.data;
          this.taskInfo.totalCount = res.totalcount;
        }

      });
    },
    onSaveProduce(type, id, val, state) {

      var data = {
        id: id
      };
      if (type == 1) {
        data.afterProduceWeight = val;
      }
      else if (type == 2) {
        data.afterProduceThickness = val;
      }
      else if (type == 3) {
        data.afterProduceWidth = val;
      }
      else if (type == 4) {
        data.afterProduceHardness = val;
      }
      else if (type == 10) {
        data.remarks = val;
      }
      this.selectInfo.id = id;
      this.selectInfo.state = state;
      this.selectInfo.data = data;

      this.showInput = true;


    },
    onConfirmInput() {
      if (this.selectInfo.state != 0 && this.selectInfo.state != 1 && this.selectInfo.state != 2) {
        this.$message("当前状态不可修改");
        return;
      }

      this.$scUtil.axiosPost(this, 'ProduceTaskApi/Execute?actcmd=edittask', this.selectInfo.data, res => {
        if (res.code == 100) {
          this.$message.success(res.message);

        }
        else {
          this.$message(res.message);
        }
        this.getTaskList();
        this.onCloseInput();
      });
    },
    onCloseInput() {
      this.showInput = false;
    },
    //
    onShowStock() {
      this.showMark = false;
      this.showStock = true;
    },
    onBackMark() {
      this.showStock = false;
      this.showMark = true;
    },
    onCloseStock() {
      this.showStock = false;
    },
    onConfirmStock(e) {
      var data = {
        taskId: this.selectInfo.id,
        warehouseId: e.warehouse_id,
        locationId: e.location_id,
        materialThickness: e.material_thickness,
        materialWidth: e.material_width,
        materialHardness: e.material_hardness,
        materialWeight: e.material_weight,
      };

      this.$scUtil.axiosPost(this, 'stockApi/Execute?actcmd=instock', data, res => {
        if (res.code == 100) {
          this.$message.success(res.message);
        }
        else {
          this.$message(res.message);
        }
        this.onCloseStock();
        this.getTaskList();
      });
    },

    onShowStockIn(row, type) {
      this.showStockIn = true;
      this.selectInfo.stockinType = type;
      this.selectInfo.id = row.id;
      this.selectInfo.thickness = row.after_produce_thickness;
      this.selectInfo.width = row.after_produce_width;
      this.selectInfo.hardness = row.after_produce_hardness;
      this.selectInfo.weight = row.after_produce_weight;
    },
    onCloseStockIn() {
      this.showStockIn = false;
    },
    onConfirmStockIn(e) {
      var data = {
        stockinType: this.selectInfo.stockinType,
        taskId: this.selectInfo.id,
        warehouseId: e.warehouse_id,
        locationId: e.location_id,
        materialThickness: e.material_thickness,
        materialWidth: e.material_width,
        materialHardness: e.material_hardness,
        materialWeight: e.material_weight,
      };

      this.$scUtil.axiosPost(this, 'stockApi/Execute?actcmd=instock', data, res => {
        if (res.code == 100) {
          this.$message.success(res.message);
        }
        else {
          this.$message(res.message);
        }
        this.onCloseStockIn();
        this.getTaskList();
      });
    },
  }
}
</script>
<style scoped>

.inputSpan{
  display: inline-block;
  width: 10px;
}
::v-deep.el-header {
  padding: 0px;
}

::v-deep.mainArea {
  width: 100%;
  height: calc(100vh - 70px);
  padding: 0 !important;
}

.mainInner {
  background-image: url(../../assets/images/img_09.png);
  background-size: 100% 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.equipmentArea {
  width: inherit;
  box-sizing: border-box;
  /* padding: 35px; */
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-table tr.el-table__row:hover {
  background-color: transparent;
}

::v-deep#app .el-table thead.is-group th.el-table__cell,
::v-deep .el-table tr,
::v-deep .el-table,
.el-table__expanded-cell,
::v-deep .el-table thead.is-group th.el-table__cell {
  background-color: transparent;
}

::v-deep.el-table {
  border: 1px solid #57606f;
}

::v-deep.el-table__body-wrapper {
  border: 1px solid #57606f;
}

::v-deep.el-container.is-vertical {
  height: 100%;
}

::v-deep.el-table .cell {
  text-align: center;
  line-height: 28px !important;
}

::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background: transparent;
}

::v-deep.el-table td.el-table__cell,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #57606f;
}

::v-deep.el-table--border .el-table__cell,
::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
::v-deep.el-table--border th.el-table__cell,
::v-deep.el-table__fixed-right-patch {
  border-right: 1px solid #57606f;
}

::v-deep.el-table--border::after,
::v-deep.el-table--group::after,
::v-deep.el-table::before {
  background-color: #57606f;
}

::v-deep.el-table thead {
  color: #79f1ff;
  font-size: 24px;
}

::v-deep.el-table td.el-table__cell {
  color: #fff;
  font-size: 24px;
}

::v-deep.el-table th.el-table__cell {
  background-color: transparent;
}

.statusLabel {
  padding: 0px 5px;
  background: #00ff00;
  color: #000000;
  display: inline-block;
  border-radius: 3px;
}

.cardBetween {
  justify-content: space-between;
}

.cardFlex {
  display: flex;
  align-items: center;
}

.cir {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
}

.green {
  background: #00ff00;
}

.gray {
  background: #9a9a9a;
}

.yellow {
  background: #fffc00;
}

.red {
  background: #ff0000;
}

.cardLine {
  margin: 0 20px;
}

.cirSize {
  width: 25px;
  height: 25px;
  margin: 0 auto;
}

::v-deep.tableInnerInput input {
  background: transparent;
  border: 1px solid #57606f;
  text-align: center;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  padding: 0 5px !important;
}

.weightInput {
  display: table-cell;
  width: 80px !important;
  margin: 0 5px !important;
}

::v-deep.weightInput input {
  background: transparent;
  border: 1px solid #57606f;
  text-align: center;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  

}

::v-deep.editBtn {
  padding: 5px 12px;
  font-size: 16px;
  border: 1px solid #00aeff;
  background: transparent;
  color: #79f1ff;
  margin: 0 0 10px 0 !important;
}

::v-deep.produceBtn {
  margin: 0 0 10px 0 !important;

}

.bzBtn {
  padding: 2px 10px;
  font-size: 16px;
  border: 1px solid #00aeff;
  background: #79f1ff;
  color: #fff;
  display: inline-block;
  border-radius: 2px;
  margin: 0 auto;
  cursor: pointer;
}

.markBtn {
  color: #000 !important;
}

.topSize {
  margin-top: 0;
}

::v-deep.el-table .el-table__cell {
  border-right: 1px solid #57606f;
}

/* ::v-deep.el-table .el-table__cell:nth-last-child(1) {
  border-right: none;
} */

::v-deep.dialogArea .el-dialog__header {
  display: none;
}

::v-deep.dialogArea .el-dialog__body {
  padding: 0;
}

::v-deep.el-table.detialTable tbody tr:hover {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

.bzText {
  color: #fff;
}



::v-deep.block .el-pagination {
  margin: 15px 0;
}

::v-deep.block .el-pager li {
  background: none;
  border: 1px solid #45526f;
  margin: 0 5px;
  color: #fff;
  cursor: pointer;
}

::v-deep.block .el-pager .active {
  color: #79f1ff !important;

}

::v-deep.block .el-pagination .btn-next,
::v-deep.block .el-pagination .btn-prev {
  background: center center no-repeat #45526f;
  color: #fff;
}

::v-deep.block .el-input__inner {
  background-color: transparent;
  border: 1px solid #45526f;
}

::v-deep.el-table.popupTable tbody tr.bgkRow {
  background: #02133a;
  box-shadow: 0px 0px 8px 0px #00b7ff inset;
}

::v-deep.el-button+.el-button {
  margin-left: 30px;
}

::v-deep.el-table .el-table__cell {
  padding: 8px 0 !important;
}

::v-deep.el-pagination button,
::v-deep.el-pagination span:not([class*=suffix]) {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 23px !important;
}

::v-deep.el-pager li {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 23px !important;
}

::v-deep.pageBlock .el-input__inner,
::v-deep.pageBlock ul li {
  height: 40px !important;
  line-height: 40px !important;
  font-size: 23px !important;
}


</style>
