<template>
	<div>
		<el-table :data="orderTitle" style="width:100%" class="dialog_table table_num">
			<el-table-column style="font-size:20px;" width="500">
				<template slot-scope="scope">
					<div style="font-weight:bolder;font-size:20px"> {{scope.row.order_no}}</div>
				</template>
			</el-table-column>
			<el-table-column width="200">
				<template>
					<div style="color: #79f1ff; font-weight:bolder">下单时间</div>
				</template>
			</el-table-column>
			<el-table-column width="200">
				<template slot-scope="scope">
					<div style="font-size:14px">{{scope.row.gmt_order}}</div>
				</template>
			</el-table-column>
		</el-table>
		<el-table :data="orderTitle" style="width:100%" class="dialog_table table_info">
			<el-table-column>
				<template><span style="color: #79f1ff; font-weight:bolder">用户</span></template>
			</el-table-column>
			<el-table-column prop="name">
				<template slot-scope="scope">
					<div style="font-size:14px">{{scope.row.customer_name}}</div>
				</template>
			</el-table-column>
			<el-table-column>
				<template>
					<div style="color: #79f1ff; font-weight:bolder">订单号</div>
				</template>
			</el-table-column>
			<el-table-column prop="num1">
				<template slot-scope="scope">
					<div style="font-size:14px">{{scope.row.order_no}}</div>
				</template>
			</el-table-column>
			<el-table-column>
				<template>
					<div style="color: #79f1ff; font-weight:bolder">物料号</div>
				</template>
			</el-table-column>
			<el-table-column prop="num2">
				<template slot-scope="scope">
					<div style="font-size:14px">{{scope.row.materialNos}}</div>
				</template>
			</el-table-column>
		</el-table>
		<el-table :data="detailList" style="width: 100%" class="dialog_table table_detail">
			<el-table-column prop="steel_grade" label="钢种" width="100">
			</el-table-column>
			<el-table-column label="规格">
				<el-table-column prop="thickness" label="厚度" width="50">
				</el-table-column>
				<el-table-column prop="thickness_tolerance" label="公差" width="60">
				</el-table-column>
				<el-table-column prop="width" label="宽度" width="55">
				</el-table-column>
				<el-table-column prop="width_tolerance" label="公差" width="52">
				</el-table-column>
			</el-table-column>
			<el-table-column prop="state_name" label="交货状态">
			</el-table-column>
			<el-table-column prop="order_weight" label="订货量(T)" width="67">
			</el-table-column>
			<el-table-column prop="gmt_delivery_plan" label="计划交货期" width="100">
			</el-table-column>
			<el-table-column prop="gmt_delivery" label="实际交货期" width="100">
			</el-table-column>
			<el-table-column label="用户要求">
				<el-table-column prop="require_hardness" label="硬度" width="50">
				</el-table-column>
				<el-table-column prop="require_stock" label="库存" width="52">
				</el-table-column>
				<el-table-column prop="require_knife_bend" label="刀弯" width="50">
				</el-table-column>
				<el-table-column prop="require_roll_diameter" label="卷径" width="50">
				</el-table-column>
				<el-table-column prop="require_other" label="其他" width="50">
				</el-table-column>
			</el-table-column>
			<el-table-column prop="remark" label="备注" width="100">
			</el-table-column>
		</el-table>
		<el-table :data="orderTitle" class="dialog_table table_remark">
			<el-table-column width="100">
				<template>
					<div style="color:#79f1ff; font-weight:bolder">备注</div>
				</template>
			</el-table-column>
			<el-table-column prop="remark">
				<template slot-scope="scope">
					<div style="font-size:14px">{{scope.row.order_remark}}</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
	export default {
		props: {
			//这里传进来的参数
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				orderTitle: [],
				detailList: []
			}
		},
		methods: {

		},
		created() {
			if (this.id > 0) {
				this.$scUtil.axiosPost(this, 'orderApi/GetOrderInfo', {
					id: this.id
				}, res => {
					if (res.code == 100) {
						let materialNos = "";
						res.data.forEach(item => {
							materialNos += item.material_no + ",";
						})
						res.data[0].materialNos = materialNos.slice(0,-1);
						this.orderTitle.push(res.data[0]);
						this.detailList = res.data;
					}
				});
			}
		}


	}
</script>
<style scoped>
	::v-deep.table_num.el-table th,
	::v-deep.table_info.el-table th,
	::v-deep.table_remark.el-table th {
		display: none;
	}

	::v-deep.el-table tr.el-table__row:hover {
		background-color: transparent;
	}

	::v-deep#app .el-table thead.is-group th.el-table__cell,
	::v-deep .el-table tr,
	::v-deep .el-table,
	.el-table__expanded-cell,
	::v-deep .el-table thead.is-group th.el-table__cell {
		background-color: transparent;
	}

	::v-deep.el-table {
		border: 1px solid #57606f;
	}

	::v-deep.el-table__body-wrapper {
		border: 1px solid #57606f;
	}

	::v-deep.el-container.is-vertical {
		height: 100%;
	}

	::v-deep.el-table .cell {
		text-align: center;
	}

	::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
		background: transparent;
	}

	::v-deep.el-table td.el-table__cell,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table th.el-table__cell.is-leaf {
		border-bottom: 1px solid #57606f;
	}

	::v-deep.el-table--border .el-table__cell,
	::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed,
	::v-deep.el-table--border th.el-table__cell,
	::v-deep.el-table__fixed-right-patch {
		border-right: 1px solid #57606f;
	}

	::v-deep.el-table--border::after,
	::v-deep.el-table--group::after,
	::v-deep.el-table::before {
		background-color: #57606f;
	}

	::v-deep.el-table thead {
		color: #79f1ff;
	}

	::v-deep.el-table td.el-table__cell {
		color: #fff;
	}

	::v-deep.table_num.el-table,
	::v-deep.table_info.el-table,
	::v-deep.table_detail.el-table,
	::v-deep.table_remark.el-table {
		border-bottom: 0px solid #57606f;
	}

	::v-deep.table_info.el-table,
	::v-deep.table_detail.el-table,
	::v-deep.table_remark.el-table {
		border-top: 0px solid #57606f;
	}

	::v-deep.table_remark .el-table__body-wrapper .el-table__row td,
	::v-deep.table_num .el-table__body-wrapper .el-table__row td,
	::v-deep.table_info .el-table__body-wrapper .el-table__row td {
		border-right: 1px solid #57606f;
	}

	::v-deep.table_remark .el-table__body-wrapper .el-table__row td:nth-last-child(1),
	::v-deep.table_num .el-table__body-wrapper .el-table__row td:nth-last-child(1),
	::v-deep.table_info .el-table__body-wrapper .el-table__row td:nth-last-child(1),
	::v-deep.table_detail.el-table {
		border-right: none;
	}

	::v-deep.bolderFont {
		font-weight: bolder;
	}
</style>
