<template>
    <div class="taggingInner">
      <div class="taggingTitle">
        {{Title}}
        <img src="../assets/images/close.png" class="closeIcon" @click="onClose"/>
      </div>
      <div class="taggingBody">
        <div class="content">{{ Content }}</div>
        <el-button class="confirmBtn" @click="onConfirm">{{ConfirmText}}</el-button>
      </div>
    </div>
  </template>
  <script>
  export default{
    props:['title','content','confirmText'],
    data(){
      return {
        Title: this.title??"提示",
        Content: this.content??'是否确认操作',
        ConfirmText: this.confirmText??'确认',
      }
    },
    created(){

    },
    methods:{
      onConfirm(){
        this.$emit('onConfirmTagging',true);
      },
      onClose(){
        this.$emit('onCloseTagging',true);
      }
    }
  }
  </script>
  <style scoped>
    .taggingTitle{
      widows: 100%;
      height: 50px;
      background: #79f1ff;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 18px;
      font-weight: bolder;
    }
  
    .closeIcon{
      position: absolute;
      right: 4%;
      width: 12px;
      height: 12px;
    }
  
    .taggingBody{
      background: #02133a;
      box-shadow: 0px 0px 8px 0px #00b7ff inset;
      width: inherit;
      padding: 60px 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .taggingBody .content{
        color: #fff;
        font-size: 30px;
    }
  
    ::v-deep.btnOk{
      width: 100%;
      height: 54px;
      background: #00ff00;
      font-size: 20px;
      font-weight: bolder;
      color: #000;
      border: none;
      border-radius: 0;
    }
  
    ::v-deep.confirmBtn{
      width: 70%;
      height: 40px;
      background: #79f1ff;
      font-size: 20px;
      font-weight: bolder;
      color: #fff;
      border: none;
      border-radius: 0;
      margin: 40px 0 0 -10px;
    }
  
  
    ::v-deep.el-button+.el-button.confirmBtn{
       margin: 40px 0 0 0px;
    }
  
    .flexBtn{
      display: flex;
      align-items: center;
    }
  
    .commonBtn{
      width: 150px;
      height: 40px;
  
      border-radius: 0;
      font-size: 14px;
    }
  
    .btnStyle1{
      background: #00ff00;
      border: 1px solid #00ff00;
      color: #000000;
    }
  
    .btnStyle2{
      border: 1px solid #fff;
      background: none;
      color: #fff;
  
    }
  
    ::v-deep.el-button+.el-button.btnStyle2{
      margin-left: 30px;
    }
  </style>